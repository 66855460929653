import React, { useEffect, useMemo, useState } from 'react'
import { useQuery, gql } from '@apollo/client'
import { storyblokEditable } from '@storyblok/react'
import { Article, Scrollbar } from '../.'
import { KnowledgeByte } from '../KnowledgeBytes/KnowledgeByte'
import * as d3 from 'd3'
import orderBy from 'lodash.orderby'

export const BoxsetRowContentSource = ({ blok, language = 'en' }) => {
	const { title, additional_topics, article_types, crypto_types, item_limit = '10', image_position, primary_topic, product_types } = blok
	const [items, setItems] = useState([])

	const filterQuery = useMemo(() => {
		const currentDate = d3.utcFormat('%Y-%m-%d %H:%M')(new Date())
		const filters = [`release_date: { lt_date: "${currentDate}" }`, `expiry_date: { gt_date: "${currentDate}" }`]

		if (primary_topic) {
			filters.push(`primary_topic: { in: "${primary_topic}" }`)
		}

		if (additional_topics && additional_topics.length > 0) {
			filters.push(`additional_topics: { in_array: "${additional_topics.join(',')}" }`)
		}

		if (article_types && article_types.length > 0) {
			filters.push(`article_type: { in: "${article_types.join(',')}" }`)
		}

		// if (categories && categories.length > 0) {
		// 	filters.push(`category: { in: "${categories.join(',')}" }`)
		// }
		if (crypto_types && crypto_types.length > 0) {
			filters.push(`crypto_types: { in_array: "${crypto_types.join(',')}" }`)
		}
		if (product_types && product_types.length > 0) {
			filters.push(`product_types: { in_array: "${product_types.join(',')}" }`)
		}

		return `, filter_query: {${filters.join(',')}}`
	}, [additional_topics, article_types, crypto_types, primary_topic, product_types])

	const query = gql`
		{
			ArticleItems (${language !== 'en' ? `starts_with: "${language}/*",` : ''} per_page: ${!item_limit ? 10 : item_limit}, sort_by: "sort_by_date:desc"${filterQuery}) {
				items {
                    content {
                        title
                        image {
                            filename
                        }
                        primary_topic {
                            uuid
                            content
                        }
                        additional_topics {
                            uuid
                            content
                        }
                        article_type {
                            uuid
                            slug
                            content
                        }
                        author {
                            name
                        }
                    }
                    uuid
                    full_slug
                    first_published_at
                }
			}
		}
	`
	const { /*loading, error,*/ data } = useQuery(query)

	const { ArticleItems } = data || {}

	useEffect(() => {
		setItems((items) =>
			orderBy(
				ArticleItems?.items.flatMap((item) => {
					const itemTopics = [item.content.primary_topic.uuid, ...(item.content.additional_topics?.map((o) => o.uuid) || [])].filter((o) => o)
					const itemTopicTitles = [item.content.primary_topic?.content?.title, ...(item.content.additional_topics?.map((o) => o.content.title) || [])].filter((o) => o)

					return {
						uuid: item.uuid,
						full_slug: item.full_slug,
						datePublished: item.first_published_at,
						topics: itemTopics,
						tags: itemTopicTitles,
						articleType: item.content.article_type.uuid,
						isKnowledgeBytes: item.content.article_type?.slug === 'knowledge-bytes',
						...item.content,
						author: item.content.author.name,
					}
				}),
				items.flatMap((o, i) => {
					return {
						...o,
						index: i,
					}
				}),
				['index'],
				['asc']
			)
		)
	}, [ArticleItems])

	return (
		<div className='boxset-contentsource' {...storyblokEditable(blok)}>
			{title && <h5>{title}</h5>}
			<div className='articles layout_3'>
				<Scrollbar>
					<div className={`boxset-articles ${image_position}`}>
						{items.map((article) => (
							<React.Fragment key={article.uuid}>
								{!article.isKnowledgeBytes && (
									<Article
										article={{
											img: image_position === 'none' ? undefined : article.image.filename,
											articleTypeName: article.article_type?.content?.title,
											...article,
										}}
									/>
								)}
								{article.isKnowledgeBytes && (
									<KnowledgeByte
										byte={{
											...article,
											header: article.article_type?.content?.title,
										}}
									/>
								)}
							</React.Fragment>
						))}
					</div>
				</Scrollbar>
			</div>
		</div>
	)
}

export default BoxsetRowContentSource
