import React, { useCallback, useState } from 'react'

import { storyblokEditable, StoryblokComponent } from '@storyblok/react'
import languageIcon from '../../icons/language.svg'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import US from '../../icons/US.svg'
import FR from '../../icons/FR.svg'
import DE from '../../icons/DE.svg'
// import IT from '../../icons/IT.svg'
// import SWE from '../../icons/SWE.svg'
// import NL from '../../icons/NL.svg'

const Navigation = ({ blok, copyright, slug, language, logo, onCloseMenu, footer, nav, className }) => {
	const [isLanguageOpen, setIsLanguageOpen] = useState(false)
	const [selectedNavigation, setSelectedNavigation] = useState('')

	const handleSelectorOpen = useCallback(() => {
		setIsLanguageOpen((isOpen) => !isOpen)
	}, [])

	const handleSelectLanguage = useCallback(
		(option) => {
			window.location = '/' + [option, slug].filter((f) => f !== '').join('/')
		},
		[slug]
	)

	return (
		<div {...storyblokEditable(blok)} className={`navigation ${className || ''}`}>
			{logo && onCloseMenu && (
				<div className='navigation-header'>
					<div className='logo'>
						{logo.map((blok) => (
							<StoryblokComponent blok={blok} key={blok._uid} />
						))}
					</div>
					<div
						className='navigation-menu-close'
						onClick={
							onCloseMenu
								? () => {
										setSelectedNavigation('')
										onCloseMenu()
								  }
								: undefined
						}
					/>
				</div>
			)}
			{blok.items.map((blok) => (
				<StoryblokComponent blok={blok} key={blok._uid} nav={nav} onSelectSubNavigation={(selectedNav) => setSelectedNavigation(selectedNav)} selectedNavigation={selectedNavigation} />
			))}

			<div className={`language-selector-container  ${isLanguageOpen ? 'open' : ''}`}>
				<div className='language-selector' onClick={handleSelectorOpen}>
					<img src={languageIcon} alt='language' />
					<span>{language}</span>
					<FontAwesomeIcon icon={'fas fa-chevron-down'} />
				</div>
				<div className='language-selector-dropdown'>
					<div className='language' key='en' onClick={() => handleSelectLanguage('')}>
						<img src={US} alt='EN' />
						<span>EN</span>
					</div>
					<div className='language' key='fr' onClick={() => handleSelectLanguage('fr')}>
							<img src={FR} alt='FR' />
							<span>FR</span>
						</div>
					<div className='language' key='de' onClick={() => handleSelectLanguage('de')}>
						<img src={DE} alt='DE' />
						<span>DE</span>
					</div>
					{/* <div className='language' onClick={() => handleSelectLanguage('it')}>
							<img src={IT} alt='IT' />
							<span>IT</span>
						</div>
						<div className='language' onClick={() => handleSelectLanguage('se')}>
							<img src={SWE} alt='SWE' />
							<span>SW</span>
						</div>
						<div className='language' onClick={() => handleSelectLanguage('nl')}>
							<img src={NL} alt='NL' />
							<span>NL</span>
						</div> */}
				</div>
			</div>

			{footer && (
				<>
					<div className='navigation-mobile' style={{ flex: 1, content: '' }} />
					{footer.map((blok) => (
						<StoryblokComponent blok={blok} key={blok._uid} className='navigation-mobile' />
					))}
				</>
			)}
		</div>
	)
}

export default Navigation
