import React from 'react'
import { Helmet } from 'react-helmet'
import { useLocation } from 'react-router-dom'
import { KnowledgeByteContent } from '../../KnowledgeBytes'
import ArticleContent from './ArticleContent'

export const StoryArticle = ({ blok, language }) => {
	const location = useLocation()
    
	const { title: pageTitle, image, metatags = {}, article_type, drop_suffix } = blok
	const { title, description, og_title, og_description, og_image } = metatags
    const { filename: articleImage } = image || {}
    
	return (
		<>
			<Helmet>
				<title>{og_title || title || pageTitle || 'Home'}{drop_suffix ? '' : ' - CoinShares'}</title>
				<meta name='description' content={og_description || description || title || pageTitle} />
				<meta property='og:title' content={og_title || title || pageTitle || 'Home'} />
				<meta property='og:type' content='article' />
				<meta property='og:url' content={window.location.origin + location.pathname} />
				<meta property='og:description' content={og_description || description || title || pageTitle} />
				<meta property='og:image' content={(og_image || articleImage) ? (og_image || articleImage) + '/m/' : window.location.origin + '/images/announcements.png'} />

                {/* twitter */}
                <meta name="twitter:card" content="summary_large_image" />
                <meta name="twitter:site" content='@CoinSharesCo' />

                {/* seo */}
				<link rel='canonical' href={window.location.origin + location.pathname} />
			</Helmet>

			{article_type.slug === 'knowledge-bytes' && <KnowledgeByteContent blok={blok} language={language}/>}
            {article_type.slug !== 'knowledge-bytes' && <ArticleContent blok={blok} />}
		</>
	)
}

export default StoryArticle
