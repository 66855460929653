import { useState, useEffect } from 'react'
import { registerStoryblokBridge, getStoryblokApi } from '@storyblok/react'

export const useStoryblok = (slug, apiOptions = {}, bridgeOptions = {}) => {
	let [story, setStory] = useState()
    // console.log('story bridge', window.location.search.includes('_storyblok'))
	useEffect(() => {
		const storyblokApi = getStoryblokApi()
		// 	storyblokApi

		async function fetchData() {
			try {
				const { data } = await storyblokApi.get(`cdn/stories/${slug}`, apiOptions)

				setStory(data.story)
			} catch (er) {
				if (er.code === 'ERR_BAD_REQUEST') {
					const { data } = await storyblokApi.get(`cdn/stories/not-found`, apiOptions)

					setStory(data.story)
				}
			}
		}

		fetchData()
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [slug])

	if (story && story.id) registerStoryblokBridge(story.id, (story) => {
        setStory(story)
        // console.log('story bridge', story)
    }, bridgeOptions)

	return story
}
