import React, { useRef } from 'react'

import { storyblokEditable, StoryblokComponent } from '@storyblok/react'
import { Scrollbar } from '.'

const Timeline = ({ blok }) => {
	const { anchor_key, background = 'white', time_cards } = blok
	const timelineContainer = useRef()

	return (
		<div className={background.toLowerCase()} id={anchor_key}>
			<div {...storyblokEditable(blok)} className='timeline'>
				<div className='container'>
					<div className='row' style={{ marginBottom: 140 }}>
						<div className='col-4'>
							<h5>{blok.title}</h5>
						</div>
						<div className='col-8'>
							<p className='pull-quote'>{blok.description}</p>
						</div>
					</div>
					<div className='row' style={{ marginTop: 20 }}>
						<div className='col' ref={timelineContainer}>
							<Scrollbar>
								{time_cards.map((blok) => (
									<div key={blok._uid} style={{ display: 'inline-block', float: 'none', flex: 'none', width: 1344 / 4 }}>
										<StoryblokComponent blok={blok} />
									</div>
								))}
							</Scrollbar>
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}

export default Timeline
