import React, { useMemo } from 'react'
import ETP from '../ETPList/ETP' 
import { useQuery, gql } from '@apollo/client'
import { storyblokEditable, StoryblokComponent } from '@storyblok/react'

export const SimilarETPs = ({ blok, language }) => {
	const { anchor_key, title, background = 'gray', description, call_to_action, etps = [] } = blok

    const query = gql`
	{
		ExchangetradedproductItems(${language !== 'en' ? `starts_with: "${language}/*",` : ''} by_uuids: "${etps.length > 0 ? etps.join(',') : "1"}") {
			items {
				content {
					name
					isin
                    description
                    card_series_suffix
				}
				uuid
                full_slug
			}
		}
	}
`

    const { /*loading, error,*/ data } = useQuery(query)

	const { ExchangetradedproductItems } = data || {}

    const featuredProducts = useMemo(() => {
		if (ExchangetradedproductItems) {
			return ExchangetradedproductItems.items.flatMap((item) => {
				return { uuid: item.uuid, full_slug: item.full_slug, ...item.content }
			})
		}

		return []
	}, [ExchangetradedproductItems])

	return (
		<div className={background.toLowerCase()} id={anchor_key}>
			<div {...storyblokEditable(blok)} className='container'>
				<div className={`row widget`}>
					<div className='col-lg-4 col-md-12 col-12 ' style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'flex-start', gap: 30 }}>
						<h5>{title}</h5>
						<p className='body-intro'>{description}</p>
						{call_to_action.map((blok) => (
							<StoryblokComponent blok={blok} key={blok._uid} />
						))}
					</div>
					<div className='col-lg-8 col-12'>
						<div className='featured-products' style={{ paddingTop: 0, paddingBottom: 0 }}>
							<div className='featured-products-list row' style={{ paddingTop: 0, paddingBottom: 0 }}>
								{featuredProducts.map((product) => (
									<div key={product.uuid} className='col-lg-4 col-md-6 col-sm-12 d-flex'>
										<ETP product={product} />
									</div>
								))}
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}

export default SimilarETPs
