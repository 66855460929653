import React, { useEffect, useRef, useMemo } from 'react'
import { useQuery, gql } from '@apollo/client'

let images = []
let frameCount = 0
const frameCountIntro = 60
let updateImage = () => {}

export const VideoScroll = (props) => {
	const { scrollingElement, videoUuid } = props
	const canvas = useRef()

	const query = gql`
    {
        BackgroundanimationItems (by_uuids:"${videoUuid || 1}") {
          items {
            content {
              frames {
                filename
              }
            }
          }
        }
      }   
    `

	const { /*loading, error,*/ data } = useQuery(query)
	const { BackgroundanimationItems } = data || {}

	const items = useMemo(() => {
		if (BackgroundanimationItems) {
			return BackgroundanimationItems.items.flatMap((o) => {
				return o.content.frames
					.map((f) => {
						return [f.filename.split('/').reverse()[0], f.filename]
					})
					.sort((a, b) => (a[0] < b[0] ? -1 : 1))
					.flatMap((o) => o[1])
			})
		}

		return []
	}, [BackgroundanimationItems])

	useEffect(() => {
		const handleSroll = () => {
			if (scrollingElement) {
				const distanceFromTop = window.scrollY + scrollingElement.getBoundingClientRect().top
				const rawPercentScrolled = (window.scrollY - distanceFromTop) / (scrollingElement.scrollHeight - window.innerHeight)

				const percentScrolled = Math.min(Math.max(rawPercentScrolled, 0), 1)
				const frameIndex = Math.min(frameCount - 1, Math.ceil(percentScrolled * (frameCount - frameCountIntro)))

				requestAnimationFrame(() => updateImage(frameIndex + frameCountIntro - 1))
			}
		}

		if (canvas && scrollingElement) {
			const context = canvas.current.getContext('2d')
			canvas.current.width = 1920
			canvas.current.height = 1080
			images = []

			if (items.length > 0) {
				frameCount = items.length
				const currentFrame = (index) => items[index]
				let lastImageDrawn = -1
				let animationFrame = 0
				let frameTimer = 0
				const animationInterval = 1000 / 30

				const preloadImages = () => {
					for (let i = 0; i < frameCount; i++) {
						const img = new Image()
						img.src = currentFrame(i)
						images.push(img)
					}
				}

				const animate = () => {
					if (lastImageDrawn < frameCountIntro) {
						animationFrame++
						updateImage(animationFrame)

						frameTimer = setTimeout(animate, animationInterval)

						if (animationFrame === frameCountIntro) {
							animationFrame = 0
							clearTimeout(frameTimer)
						}
					}
				}

				updateImage = (index) => {
					if (lastImageDrawn !== index && images.length > 0) {
						context.clearRect(0, 0, 1920, 1080)
						context.drawImage(images[index], 0, 0)
						lastImageDrawn = index
					}
				}

				preloadImages()
				animate()
			} else {
				context.clearRect(0, 0, 1920, 1080)
			}
		}

		document.addEventListener('scroll', handleSroll)

		return () => {
			document.removeEventListener('scroll', handleSroll)
		}
	}, [items, scrollingElement])

	return <canvas ref={canvas} className='background-scroll'></canvas>
}

export default VideoScroll
