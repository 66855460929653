import React from 'react'

import { storyblokEditable } from '@storyblok/react'
import BackChevron from '../../icons/BackChevron.svg'
import { Link } from 'react-router-dom'

export const Breadcrumb = ({ blok }) => {
	const { link } = blok

    if (link.cached_url) {
        return (
            <Link {...storyblokEditable(blok)} to={link.cached_url || '/'} className='breadcrumb'>
                <img src={BackChevron} alt="back"/><span>{blok.title}</span>
            </Link>
        )
    } else 
	return (
		<a {...storyblokEditable(blok)} href={link.url} className='breadcrumb'>
			<img src={BackChevron} alt="back"/><span>{blok.title}</span>
		</a>
	)
}

export default Breadcrumb
