import React, { useState } from 'react'

import { storyblokEditable, StoryblokComponent } from '@storyblok/react'
import { useCallback } from 'react'

export const Carousel = ({ blok }) => {
	const { anchor_key, background = 'White', layout, card_description, title, cards, paragraph } = blok
	const [cardPositions, setCardPositions] = useState(
		cards.map((item, index) => {
			return {
				index: index,
				pos: 0,
				center: false,
			}
		})
	)

	const onMiddleChange = useCallback((index, middle) => {
		setCardPositions((items) => {
			const positions = [...items] || []
			const fIndex = (positions || []).findIndex((p) => p.index === index)

			if (fIndex >= 0) {
				positions[fIndex].pos = parseInt(middle)

				const goal = 50

				const closest = positions.reduce((prev, curr) => (Math.abs(curr.pos - goal) < Math.abs(prev.pos - goal) ? curr : prev))

				positions[fIndex].center = closest.index === index
			}
            
			return positions
		})
	}, [])

	return (
		<div className={`${background.toLowerCase()} carousel ${layout}`} id={anchor_key}>
			<div className='container'>
				<div {...storyblokEditable(blok)} className='row'>
					<div className='col-lg-6 col-md-12 d-flex flex-column' style={{ gap: 80 }}>
						{(layout === 'layout_3' || layout === 'layout_5') && <h5>{title}</h5>}
						{paragraph.map((blok) => (
							<StoryblokComponent blok={blok} key={blok._uid} sticky={true}/>
						))}
					</div>
					<div className='col-lg-6 col-md-12'>
						{layout !== 'layout_3' && layout !== 'layout_5' && <h5>{title}</h5>}
						<div className='carousel-cards'>
							{cards.map((blok, index) => (
								<StoryblokComponent blok={blok} key={blok._uid} index={index} count={cards.length} layout={layout} onMiddleChange={onMiddleChange} positions={cardPositions} />
							))}
						</div>

						<p className='page-content'>{card_description}</p>
					</div>
				</div>
			</div>
		</div>
	)
}

export default Carousel
