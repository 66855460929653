import React, { useEffect } from 'react'
import { useParams } from 'react-router-dom'

export const DownloadBox = () => {
	const { key } = useParams()

	useEffect(() => {
		if (key) {
			fetch(`${process.env.REACT_APP_API_URI}/integrationfolders/box/assets/download/url/${key}?ApiKey=${process.env.REACT_APP_API_KEY}`, {
				method: 'GET',
				headers: {
					'Content-Type': 'application/json',
				},
			}).then((value) => {
				value.json().then((value) => {
					if (value) {
						window.open(value, '_self')
					}
				})
			})
		}
	}, [key])

	return (
		<div className='transparent widget'>
			<div className='container'>
				<div className='row'>
					<div className='col-8 offset-2'>
						<h1 className='content'>Downloading...</h1>
						<p className='body-intro'>Please wait while we prepare your files for download...</p>
					</div>
				</div>
			</div>
		</div>
	)
}

export default DownloadBox
