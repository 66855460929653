import React, { useMemo, useState } from 'react'

import { storyblokEditable, StoryblokComponent } from '@storyblok/react'
import { Dropdown, DirectionalHint } from '@fluentui/react'
import { useEffect } from 'react'

export const TabStrip = ({ blok }) => {
	const { background, title, header, description, tabs, layout, anchor_key, use_inline } = blok

	const [selectedTab, setSelectedTab] = useState(tabs.length > 0 ? tabs[0] : undefined)

	useEffect(() => {
		setSelectedTab((selected) => tabs.find((t) => t._uid === selected._uid) || tabs[0])
	}, [tabs])

	const renderTabs = useMemo(
		() => (
			<>
				{layout === 'layout_2' ? (
					<div className='center-header'>
						<h2 className='display'>{header || title}</h2>

						<span className='body-intro'>{description}</span>
					</div>
				) : (
					<>{title && <h5>{title}</h5>}</>
				)}

				<div className='tabs' id={anchor_key}>
					<div className='tab-headers'>
                    {tabs.filter(o => o.title).length > 0 && <Dropdown
							calloutProps={{
								directionalHint: DirectionalHint.bottomLeftEdge,
								directionalHintFixed: true,
							}}
							className='tab-dropdown'
							options={tabs.map((o) => {
								return {
									key: o._uid,
									text: o.title,
								}
							})}
							selectedKey={selectedTab?._uid}
							onChange={(ev, option) => setSelectedTab(tabs.find((o) => o._uid === option.key))}
						/>}
						{tabs.map((blok) => (
							<StoryblokComponent blok={blok} key={blok._uid} selected={selectedTab === blok} onSelectedKeyChanged={(tab) => setSelectedTab(tab)} />
						))}
					</div>
					<div className='tab-content'>
						{selectedTab?.content && <>{selectedTab.content}</>}
						{selectedTab?.body && selectedTab.body.map((blok) => <StoryblokComponent blok={blok} key={blok._uid} />)}
					</div>
				</div>
			</>
		),
		[anchor_key, description, header, layout, selectedTab, tabs, title]
	)

	return (
		<>
			{background && !use_inline && (
				<div className={background.toLowerCase()}>
					<div {...storyblokEditable(blok)} className='tab-strip'>
						<div className='container'>
							<div className='row'>
								<div className='col'>{renderTabs}</div>
							</div>
						</div>
					</div>
				</div>
			)}

			{!background && !use_inline && <div>{renderTabs}</div>}

            {use_inline && <div className='tab-strip inline'>{renderTabs}</div>}
		</>
	)
}

export default TabStrip
