import React, { useEffect, useState } from 'react'
import { storyblokEditable } from '@storyblok/react'
import * as d3 from 'd3'

export const FairValueCalculation = ({ blok }) => {
	const { anchor_key, title, cache_key } = blok
    const [fairValueValues, setFairValueValues] = useState()
    const { AccruedFee, Date: fairValueDate, Divisor, FXrate, FairValue, IndexPrice1, IndexPrice2, IndexPrice3, IndexPrice_Less_AccruedFee, IndexPrice_X_FXrate } = fairValueValues || {}

    useEffect(() => {
		if (cache_key) {
			fetch(`${process.env.REACT_APP_API_URI}/Widgets?ApiKey=${process.env.REACT_APP_API_KEY}&names=XBT_PROVFAIRVALUE_${cache_key}`, {
				method: 'GET',
				headers: {
					'Content-Type': 'application/json',
				},
			}).then((value) => {
				value.json().then((value) => {
					
                    const widget = value.find((val) => val.key === `XBT_PROVFAIRVALUE_${cache_key.toUpperCase()}`)
				    const section = widget.sections.find((section) => section.key === cache_key.toUpperCase())

                    const fairValueCalc = {}
                    section.meta.forEach((o, i) => fairValueCalc[o.key] = o)
                    
                    setFairValueValues(fairValueCalc)
				})
			})
		}
	}, [cache_key])

	return (
		<div className='white' id={anchor_key}>
			<div {...storyblokEditable(blok)} className='widget'>
				<div className='container'>
                    {!cache_key && <>No Cache Key!</>}
					{cache_key && <div className='row'>
						<div className='col-lg-4 col-12'>
							<h5>{title}</h5>
						</div>
						<div className='col-lg-8 col-12'>
							<div className='row'>
								<div className='fairvalue'>
									<span className='fairvalue-header'>
										<span>Fair Value </span>
										<span>{FairValue?.value} </span>
										<span style={{ fontSize: 30 }}>{FairValue?.suffix}</span>
									</span>

									<div className='divider' />

									<div className='fairvalue-calc'>
										<div className='row'>
											<div className='col fairvalue-unit'>
												<span>{IndexPrice1?.prefix} {IndexPrice1?.value}</span>
												<span>Index Price</span>
											</div>
											<div className='col fairvalue-unit'>
												<span>X</span>
											</div>
											<div className='col fairvalue-unit'>
												<span>{FXrate?.value}</span>
												<span>Fixed Prices</span>
											</div>
										</div>

										<div className='candle'>
											<div className='candle-value'>{IndexPrice_X_FXrate?.value} <span style={{ fontSize: 15 }}>{IndexPrice_X_FXrate?.suffix}</span></div>
										</div>
									</div>

									<div className='fairvalue-calc'>
										<div className='row'>
											<div className='col fairvalue-unit'>
												<span>{IndexPrice2?.value}</span>
												<span>Index Price</span>
											</div>
											<div className='col fairvalue-unit'>
												<span>-</span>
											</div>
											<div className='col fairvalue-unit'>
												<span>
													{AccruedFee?.value} <span style={{ fontSize: 30 }}>{AccruedFee?.suffix}</span>
												</span>
												<span>ACCRUED FEE SINCE INCEPTION</span>
											</div>
										</div>

										<div className='candle'>
											<div className='candle-value'>
												{IndexPrice_Less_AccruedFee?.value} <span style={{ fontSize: 15 }}>{IndexPrice_Less_AccruedFee?.suffix}</span>
											</div>
										</div>
									</div>

									<div className='fairvalue-calc'>
										<div className='row'>
											<div className='col fairvalue-unit'>
												<span>{IndexPrice3?.value}</span>
												<span>Index Price - Fee</span>
											</div>
											<div className='col fairvalue-unit'>
												<span>X</span>
											</div>
											<div className='col fairvalue-unit'>
												<span>{Divisor?.value}</span>
												<span>Divisor</span>
											</div>
										</div>
									</div>

									<div className='divider' />

									<div className='row'>
										<div className='fairvalue-disclaimer'>
											<span>Last updated {d3.utcFormat('%Y-%m-%d %H:%M:%S')(new Date(fairValueDate?.value))} UTC</span>
											<span className='text-light'>*Fair Value is not indicative of current market price; Index sources listed in prospectus</span>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>}
				</div>
			</div>
		</div>
	)
}

export default FairValueCalculation
