import React from 'react'
import { useGlobalState } from '../../store'

import { storyblokEditable, StoryblokComponent } from '@storyblok/react'
import { GlossaryHighlighter } from '../KnowledgeBytes/Highlighter'

const RichTextHeading = ({ children, level, stickyHeaders, isContent }) => {
	const id = children.toLowerCase().replace(/ /g, '_')
	const classNames = []
	if (stickyHeaders) classNames.push('sticky-header')
	if (isContent) classNames.push('content')

	switch (level) {
		case 1:
			return (
				<h1 id={id} className={classNames.join(' ')}>
					{children}
				</h1>
			)
		case 2:
			return (
				<h2 id={id} className={classNames.join(' ')}>
					{children}
				</h2>
			)
		case 3:
			return (
				<h3 id={id} className={classNames.join(' ')}>
					{children}
				</h3>
			)
		case 4:
			return (
				<h4 id={id} className={classNames.join(' ')}>
					{children}
				</h4>
			)
		case 5:
			return (
				<h5 id={id} className={classNames.join(' ')}>
					{children}
				</h5>
			)
		case 6:
			return (
				<h6 id={id} className={classNames.join(' ')}>
					{children}
				</h6>
			)
		default:
			return (
				<h1 id={id} className={classNames.join(' ')}>
					{children}
				</h1>
			)
	}
}

const RichTextContent = ({ content, stickyHeaders, isContent }) => {
	const { lookupTerms } = useGlobalState()

	return (
		<>
			{content.map((contentItem, index) => {
				const key = `${contentItem.type}${index}`

				if (contentItem.type === 'paragraph') {
					if (!contentItem.content) {
						return <p key={key}>&nbsp;</p>
					}
					return (
						<p key={key}>
							<RichTextContent content={contentItem.content} />
							{/* {contentItem.content
								.filter((f) => f.type === 'text')
								.map((text) => {
									
								})} */}
						</p>
					)
				} else if (contentItem.type === 'ordered_list') {
					return (
						<ol key={key}>
							<RichTextContent content={contentItem.content} />
						</ol>
					)
				} else if (contentItem.type === 'bullet_list') {
					return (
						<ul key={key}>
							<RichTextContent content={contentItem.content} />
						</ul>
					)
				} else if (contentItem.type === 'list_item') {
					return (
						<li key={key} style={{ marginLeft: 40 }}>
							<RichTextContent content={contentItem.content} />
						</li>
					)
				} else if (contentItem.type === 'heading') {
					const { attrs, content } = contentItem
					const text = content
						?.filter((item) => item.type === 'text')
						.map((text) => text.text)
						.join('')

					return (
						<div key={key}>
							{text && (
								<RichTextHeading isContent={isContent} key={text} level={attrs.level} stickyHeaders={stickyHeaders}>
									{text}
								</RichTextHeading>
							)}
						</div>
					)
				} else if (contentItem.type === 'blok') {
					const { body } = contentItem.attrs
					return body.map((item) => <StoryblokComponent key={item._uid} blok={item} />)
				} else if (contentItem.type === 'image') {
					// contentItem.attrs.src = contentItem.attrs.src + '/m/'
					return <img key={key} alt='' {...contentItem.attrs} src={contentItem.attrs.src + '/m/'} width='100%' />
				} else if (contentItem.type === 'text') {
					const className = (contentItem.marks || []).map((mark) => 'text-' + mark.type)
					return (
						<span key={contentItem.text} className={className.join(' ')}>
							{contentItem.marks && contentItem.marks.find((mark) => mark.type === 'link') ? (
								contentItem.marks
									.filter((mark) => mark.type === 'link')
									.map((mark) => {
										const { attrs } = mark
										const { linktype = 'url', target, href } = attrs || {}

										return (
											<a key={mark} href={linktype === 'url' || linktype === null ? href : `mailto:${href}`} target={target === null ? '_blank' : target} rel='noreferrer'>
												<GlossaryHighlighter renderTooltip={true} glossaryTerms={lookupTerms.flatMap((o) => o.value)} textToHighlight={contentItem.text} />
												{/* {contentItem.text} */}
											</a>
										)
									})
							) : (
								<>
									<GlossaryHighlighter renderTooltip={true} glossaryTerms={lookupTerms.map((o) => o.value)} textToHighlight={contentItem.text} />
									{/* {contentItem.text} */}
								</>
							)}
						</span>
					)
				} else if (contentItem.type === 'hard_break') {
					return <br key={key} />
				} else if (contentItem.type === 'blockquote') {
					return (
						<span className='quote-container' style={{ paddingTop: 40, paddingBottom: 40, display: 'inline-flex' }} key={key}>
							<span className='quote'>
								<RichTextContent content={contentItem.content} />
							</span>
						</span>
					)
				} else if (contentItem.type === 'horizontal_rule') {
					return <hr key={key} />
				}

				return <React.Fragment key={key} />
			})}
		</>
	)
}

export const extractRichText = ({ blok }) => {
	const { text, body, content: blokContent } = blok
	const { content } = text || body || { content: blokContent }

	return content
		.flatMap((contentItem) => {
			if (contentItem.type === 'paragraph') {
				if (!contentItem.content) {
					return ' '
				}
				return extractRichText({ blok: { content: contentItem.content } })
			} else if (contentItem.type === 'ordered_list') {
				return extractRichText({ blok: { content: contentItem.content } })
			} else if (contentItem.type === 'bullet_list') {
				return extractRichText({ blok: { content: contentItem.content } })
			} else if (contentItem.type === 'list_item') {
				return extractRichText({ blok: { content: contentItem.content } })
			} else if (contentItem.type === 'heading') {
				const { content } = contentItem
				const text = content
					?.filter((item) => item.type === 'text')
					.map((text) => text.text)
					.join('')

				return text
			} else if (contentItem.type === 'text') {
				return contentItem.text
			} else if (contentItem.type === 'blockquote') {
				return extractRichText({ blok: { content: contentItem.content } })
			}

			return ''
		})
		.join(' ')
}

export const RichText = ({ blok, inline, stickyHeaders, content: isContent }) => {
	const { anchor_key, background = 'white', text, body, title, content: blokContent, collapse_paragraph_padding } = blok
	const { content } = text || body || { content: blokContent }
	const { type } = content

	return (
		<>
			{!inline && (
				<div className={background.toLowerCase()} id={anchor_key}>
					<div {...storyblokEditable(blok)} className={body ? '' : 'widget'}>
						<div className='container'>
							<div className='row'>
								{type !== 'doc' && <h5>{title}</h5>}

								<div className={collapse_paragraph_padding ? 'collapse-paragraph-padding' : ''}>
									<RichTextContent content={content} />
								</div>
							</div>
						</div>
					</div>
				</div>
			)}

			{inline && (
				<div id={anchor_key}>
					<RichTextContent content={content} stickyHeaders isContent />
				</div>
			)}
		</>
	)
}

export default RichText
