import React, { useMemo, useRef, useEffect, useState } from 'react'

import { storyblokEditable } from '@storyblok/react'
import { Link } from 'react-router-dom'
import { useCallback } from 'react'
import { getCookie } from '../PopupNotice'

export const KnowledgeByte = ({ blok, byte, backgroundImage, viewed }) => {
	const { key, media_type, title, tags, tagIcon, tagIconHover, full_slug, is_question_card } = byte
	// const [showGif, setShowGif] = useState(false)

	const className = useMemo(() => {
		const classNames = ['knowledge-byte']

		if (!is_question_card) classNames.push('inverted')
		if (is_question_card) classNames.push('show-image')
        if (viewed) classNames.push('viewed')

		return classNames.join(' ')
	}, [is_question_card, viewed])

	const [hover, setHover] = useState(false)

	const linkRef = useRef()
	useEffect(() => {
		if (linkRef && linkRef.current) {
			linkRef.current.addEventListener('mouseenter', () => setHover(true))
			linkRef.current.addEventListener('mouseleave', () => setHover(false))
		}
	}, [linkRef])
	// 	if (is_question_card) {
	//         console.log(backgroundImage * 5000)
	// 		setTimeout(() => {
	// 			setShowGif(true)
	// 		}, (backgroundImage * 5000))

	//         setTimeout(() => {
	//             setShowGif(false)
	//         }, ((backgroundImage * 5000) + 5000))

	// 	}
	// }, [backgroundImage, is_question_card])

	const handleLogAction = useCallback(() => {
		fetch(`${process.env.REACT_APP_API_URI}/CustomActions?ApiKey=${process.env.REACT_APP_API_KEY}`, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify([{ key: getCookie('visitor_id882933'), name: 'viewed-kb', data: JSON.stringify({ source: 'website', code: key }) }]),
		})
	}, [key])

	return (
		<Link to={full_slug} {...storyblokEditable(blok)} className={className} ref={linkRef} onClick={handleLogAction}>
			<div className='knowledge-byte-header'>
				{viewed ? <span className='tag viewed'>Viewed Before</span> : <span></span>}
				{media_type && <img src={is_question_card || hover ? `/images/detail-${media_type}-light.svg` : `/images/detail-${media_type}.svg`} alt={media_type} />}
			</div>
			<h4 className='knowledge-byte-title'>{title}</h4>

			<div className='knowledge-tags'>
				{tagIcon && (
					<span className='tag icon' style={{ padding: 6, paddingBottom: 4 }}>
						<img height={12} src={tagIcon.filename} alt={title} />
					</span>
				)}
				{tagIconHover && (
					<span className='tag icon-hover' style={{ padding: 6, paddingBottom: 4 }}>
						<img height={12} src={tagIconHover.filename} alt={title} />
					</span>
				)}
				{tags.map((tag) => (
					<span key={tag} className='tag'>
						{tag}
					</span>
				))}
			</div>
		</Link>
	)
}

export default KnowledgeByte
