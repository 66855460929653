import React from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter } from 'react-router-dom'
import 'bootstrap/dist/css/bootstrap-grid.min.css'
import './index.css'
import './styles/styles.scss'
import App from './App'
// import reportWebVitals from './reportWebVitals';

import { storyblokInit, apiPlugin } from '@storyblok/react'
import { ApolloClient, InMemoryCache, ApolloProvider, ApolloLink, concat, HttpLink } from '@apollo/client'

import { library } from '@fortawesome/fontawesome-svg-core'
import { fas } from '@fortawesome/free-solid-svg-icons'
import { fab } from '@fortawesome/free-brands-svg-icons'

import Page from './components/Page'
// import Grid from './components/Grid'
import Footer from './components/Layout/Footer'
import Header from './components/Layout/Header'
import Global from './components/Layout/Global'
import IFrame from './components/Layout/IFrame'
import ImageLink from './components/ImageLink'
import Navigation from './components/Layout/Navigation'
import NavigationItem from './components/Layout/NavigationItem'
import Column from './components/Column'
import ProfileCard from './components/ProfileCard'
import Timeline from './components/Timeline'
import Team from './components/Team'
import PortfolioCard from './components/PortfolioCard'
import TimeCard from './components/TimeCard'
import AssetSummary from './components/Layout/AssetSummary'
import RelatedNews from './components/RelatedNews'
import { initializeIcons } from '@fluentui/react'

import {
	Announcements,
	Articles,
	BoxSets,
	Breadcrumb,
	Button,
	CardSet,
	Carousel,
	CarouselCard,
	ETPList,
	ExchangeList,
	FeaturedProducts,
	Headline,
	Information,
	InvestmentPortfolio,
	KeyInformation,
	KnowledgeBytes,
	MarketPerformanceGraph,
	Nasdaq,
	OfferingSummary,
	Paragraph,
	Quote,
	RichText,
	Row,
	TabStrip,
	Tab,
	TwitterFeed,
	Volume,
	ETPsTotalAmountInvested,
	BenchmarkGraph,
	IndexList,
	FeatureStatements,
	SimilarETPs,
	FairValueCalculation,
	DailyHedgingPosition,
	IndexForm,
	KnowledgeView,
    StoryArticle,
    NewsLetterForm,
    InlineTable,
    DocumentList,
    InvestorCapTable,
    ButtonStrip,
    ContactForm,
    StructureDiagram,
    BrokerSelect,
    ComplexTable,
    StakingRewards,
    StatisticsList,
    ETPDocuments,
    PopupNotice,
	ProductAlert,
    QuarterlyAnnualReportsQuarter,
    KnowledgeBytesTopic,
    BoxsetTableOfContents,
    BoxsetRow,
    BoxsetEvidence,
    BoxsetRowContentSource,
    BoxsetRowContentGraph,
    Owl
} from './components'
import { TwoColumn } from './components/Grid'
import TwoColumnRowSplit from './components/Layout/TwoColumnRowSplit'
import Container from './components/Layout/Container'

library.add(fas, fab)
initializeIcons()

const token = window.location.search.includes('_storyblok') ? process.env.REACT_APP_PREVIEW_TOKEN : process.env.REACT_APP_PUBLIC_TOKEN
const version = window.location.search.includes('_storyblok') ? 'draft' : 'published'

storyblokInit({
	accessToken: token,
	use: [apiPlugin],
	components: {
		// grid: Grid,
		announcements: Announcements,
        article: StoryArticle,
		articles: Articles,
		asset_summary: AssetSummary,
		benchmark_graph: BenchmarkGraph,
		box_sets: BoxSets,
        boxset_row: BoxsetRow,
        boxset_table_of_contents: BoxsetTableOfContents,
        boxset_evidence: BoxsetEvidence,
        boxset_row_content_source: BoxsetRowContentSource,
        boxset_row_content_graph: BoxsetRowContentGraph,
		breadcrumb: Breadcrumb,
        broker_selector: BrokerSelect,
		button: Button,
        button_strip: ButtonStrip,
		card: CarouselCard,
		card_set: CardSet,
		carousel: Carousel,
		column: Column,
        complex_table: ComplexTable,
        contact_form: ContactForm,
        container: Container,
		daily_hedging_position: DailyHedgingPosition,
        document_list: DocumentList,
        etp_product_documents: ETPDocuments,
		etps_total_amount_invested: ETPsTotalAmountInvested,
		exchange_list: ExchangeList,
		exchange_traded_product: Page,
		exchange_traded_product_list: ETPList,
		fair_value_calculation: FairValueCalculation,
		featured_products: FeaturedProducts,
		feature_statements: FeatureStatements,
		footer: Footer,
		global: Global,
		header: Header,
		headline: Headline,
		image_link: ImageLink,
        iframe: IFrame,
		index: Page,
		index_form: IndexForm,
		index_list: IndexList,
		inline_content: RichText,
        inline_table: InlineTable,
		information: Information,
		investment_portfolio: InvestmentPortfolio,
        investor_cap_table: InvestorCapTable,
		key_information: KeyInformation,
		knowledge_view: KnowledgeView,
		knowledge_bytes: KnowledgeBytes,
        knowledge_bytes_topic: KnowledgeBytesTopic,
        legal_notice: Page,
		market_performance_graph: MarketPerformanceGraph,
		nasdaq: Nasdaq,
		navigation: Navigation,
		navigation_item: NavigationItem,
        newsletter_form: NewsLetterForm,
		offering_summary: OfferingSummary,
        owl: Owl,
		page: Page,
		paragraph: Paragraph,
		profile_card: ProfileCard,
        popup_notice: PopupNotice,
		portfolio_card: PortfolioCard,
		product_alert: ProductAlert,
        quarterly_annual_reports_quarter: QuarterlyAnnualReportsQuarter,
		quote: Quote,
		related_news: RelatedNews,
		rich_text: RichText,
		row: Row,
		similar_etps: SimilarETPs,
        staking_rewards: StakingRewards,
        statistics_list: StatisticsList,
        structure_diagram: StructureDiagram,
		social_media_icon: ImageLink,
		tab_strip: TabStrip,
		tab: Tab,
		team: Team,
		timeline: Timeline,
		time_card: TimeCard,
		two_column: TwoColumn,
        two_column_row_split: TwoColumnRowSplit,
		twitter_feed: TwitterFeed,
		volume: Volume,
		// exchange_list: Row
	},
})

const httpLink = new HttpLink({ uri: process.env.REACT_APP_STORYBLOK_GRAPH_API })

const authMiddleware = new ApolloLink((operation, forward) => {
	operation.setContext(({ headers = {} }) => ({
		headers: {
			...headers,
			token: token,
			version: version,
		},
	}))
	return forward(operation)
})

const client = new ApolloClient({
	cache: new InMemoryCache(),
	link: concat(authMiddleware, httpLink),
})
// const root = ReactDOM.createRoot(document.getElementById('root'));
// root.render(
//   <React.StrictMode>
//     <App />
//   </React.StrictMode>
// );

ReactDOM.render(
	<React.StrictMode>
		<ApolloProvider client={client}>
			<BrowserRouter basename='/'>
				<App />
			</BrowserRouter>
		</ApolloProvider>
	</React.StrictMode>,
	document.getElementById('root')
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
