import { Checkbox, Dropdown, TextField, DirectionalHint } from '@fluentui/react'
import React, { useState } from 'react'
import { RichText } from '../RichText'
import useForm from '../../hooks/useForm'
import { useCallback } from 'react'
import { useLocation } from 'react-router-dom'

const countries = [
	{
		name: 'Andorra',
		code: 'AD',
	},
	{
		name: 'Albania',
		code: 'AL',
	},
	{
		name: 'Austria',
		code: 'AT',
	},
	{
		name: 'Åland Islands',
		code: 'AX',
	},
	{
		name: 'Bosnia and Herzegovina',
		code: 'BA',
	},
	{
		name: 'Belgium',
		code: 'BE',
	},
	{
		name: 'Bulgaria',
		code: 'BG',
	},
	{
		name: 'Belarus',
		code: 'BY',
	},
	{
		name: 'Switzerland',
		code: 'CH',
	},
	{
		name: 'Cyprus',
		code: 'CY',
	},
	{
		name: 'Czech Republic',
		code: 'CZ',
	},
	{
		name: 'Germany',
		code: 'DE',
	},
	{
		name: 'Denmark',
		code: 'DK',
	},
	{
		name: 'Estonia',
		code: 'EE',
	},
	{
		name: 'Spain',
		code: 'ES',
	},
	{
		name: 'Finland',
		code: 'FI',
	},
	{
		name: 'Faroe Islands',
		code: 'FO',
	},
	{
		name: 'France',
		code: 'FR',
	},
	{
		name: 'United Kingdom',
		code: 'GB',
	},
	{
		name: 'Guernsey',
		code: 'GG',
	},
	{
		name: 'Greece',
		code: 'GR',
	},
	{
		name: 'Croatia',
		code: 'HR',
	},
	{
		name: 'Hungary',
		code: 'HU',
	},
	{
		name: 'Ireland',
		code: 'IE',
	},
	{
		name: 'Isle of Man',
		code: 'IM',
	},
	{
		name: 'Iceland',
		code: 'IC',
	},
	{
		name: 'Italy',
		code: 'IT',
	},
	{
		name: 'Jersey',
		code: 'JE',
	},
	{
		name: 'Liechtenstein',
		code: 'LI',
	},
	{
		name: 'Lithuania',
		code: 'LT',
	},
	{
		name: 'Luxembourg',
		code: 'LU',
	},
	{
		name: 'Latvia',
		code: 'LV',
	},
	{
		name: 'Monaco',
		code: 'MC',
	},
	{
		name: 'Moldova, Republic of',
		code: 'MD',
	},
	{
		name: 'Macedonia, The Former Yugoslav Republic of',
		code: 'MK',
	},
	{
		name: 'Malta',
		code: 'MT',
	},
	{
		name: 'Netherlands',
		code: 'NL',
	},
	{
		name: 'Norway',
		code: 'NO',
	},
	{
		name: 'Poland',
		code: 'PL',
	},
	{
		name: 'Portugal',
		code: 'PT',
	},
	{
		name: 'Romania',
		code: 'RO',
	},
	{
		name: 'Russian Federation',
		code: 'RU',
	},
	{
		name: 'Sweden',
		code: 'SE',
	},
	{
		name: 'Slovenia',
		code: 'SI',
	},
	{
		name: 'Svalbard and Jan Mayen',
		code: 'SJ',
	},
	{
		name: 'Slovakia',
		code: 'SK',
	},
	{
		name: 'San Marino',
		code: 'SM',
	},
	{
		name: 'Ukraine',
		code: 'UA',
	},
	{
		name: 'Holy See (Vatican City State)',
		code: 'VA',
	},
]

const contactValidator = {
	firstname: {
		required: true,
		length: 250,
	},
	lastname: {
		required: true,
		length: 250,
	},
	email: {
		required: true,
		length: 250,
		validator: {
			regEx: /^([a-zA-Z0-9_\-.]+)@([a-zA-Z0-9_\-.]+)\.([a-zA-Z]{2,15})$/,
			error: 'E-mail must be a valid e-mail address, e.g. user@company.com',
		},
	},
	phone_number: {
		required: false,
		validator: {
			error: 'Number must be in the correct format (i.e.: +44101234567)',
			regEx: /^\+[1-9]\d{1,14}$/,
		},
	},
	message: {
		required: false,
		length: 1500,
	},
}

const defaultFormValue = {
	firstname: '',
	lastname: '',
	email: '',
	phone_number: '',
	country: '',
	areas_of_interest: '',
	website_form_location: '',
	website_form_type: '',
	message: '',
}

export const ContactForm = ({ blok }) => {
	const location = useLocation()

	const { title, header, description, form_type, confirmation_title, confirmation_description, has_message, has_areas_of_interest, privacy_policy, pardot_endpoint, disclaimer } = blok

	const [showConfirmation, setShowConfirmation] = useState(false)
	const [acknowledged, setAcknowledged] = useState(false)
	const [formSrc, setFormSrc] = useState('')

	const handleFormSave = useCallback(
		(formValues) => {
			const fullValues = {
				...formValues,
				website_form_location: location.pathname.substring(1),
				website_form_type: form_type,
			}

			const pardotValues = Object.entries(fullValues)
				.map((o) => `${o[0]}=${o[0] !== 'areas_of_interest' ? encodeURIComponent(o[1]) : o[1]}`)
				.join('&')

			setFormSrc(`${pardot_endpoint}?${pardotValues}`)
			setShowConfirmation(true)
		},
		[form_type, location, pardot_endpoint]
	)

	const { formState, handleOnChange, handleOnSubmit } = useForm(defaultFormValue, contactValidator, handleFormSave)

	const handleAreaChange = useCallback(
		(area) => {
			const areas = formState.areas_of_interest.value.split(',')

			const areaIndex = areas.findIndex((o) => o === area)

			if (areaIndex >= 0) {
				areas.splice(areaIndex, 1)
			} else areas.push(area)

			if (handleOnChange) handleOnChange('areas_of_interest', areas.filter((o) => o).join(','))
		},
		[formState.areas_of_interest.value, handleOnChange]
	)

	return (
		<>
			{!showConfirmation && (
				<form className='contact-form'>
					{header && <h5>{header}</h5>}
					{title && <h3 className='content'>{title}</h3>}

					{description && (
						<div className='body-intro'>
							<RichText inline={true} blok={description} />
						</div>
					)}

					{/* <div className='container'> */}
					<div className='row form'>
						<div className='col-md-6 col-12'>
							<div className='form-input'>
								<TextField
									name='firstname'
									className={formState.firstname.error ? 'error' : ''}
									onChange={(ev, value) => {
										if (handleOnChange) handleOnChange('firstname', value)
									}}
									defaultValue={formState.firstname.value}
									label='First Name*'
									errorMessage={formState.firstname.error}
								/>
							</div>
						</div>
						<div className='col-md-6 col-12'>
							<div className='form-input'>
								<TextField
									name='lastname'
									className={formState.lastname.error ? 'error' : ''}
									onChange={(ev, value) => {
										if (handleOnChange) handleOnChange('lastname', value)
									}}
									defaultValue={formState.lastname.value}
									label='Last Name*'
									errorMessage={formState.lastname.error}
								/>
							</div>
						</div>

						<div className='col-md-6 col-12'>
							<div className='form-input'>
								<TextField
									name='email'
									className={formState.email.error ? 'error' : ''}
									onChange={(ev, value) => {
										if (handleOnChange) handleOnChange('email', value)
									}}
									defaultValue={formState.email.value}
									label='Email*'
									errorMessage={formState.email.error}
								/>
							</div>
						</div>
						<div className='col-md-6 col-12'>
							<div className='form-input'>
								<TextField
									name='phone_number'
									className={formState.phone_number.error ? 'error' : ''}
									onChange={(ev, value) => {
										if (handleOnChange) handleOnChange('phone_number', value)
									}}
									defaultValue={formState.phone_number.value}
									label='Phone Number'
									errorMessage={formState.phone_number.error}
								/>
							</div>
						</div>
						<div className='col-md-6 col-12'>
							<div className='form-input'>
								<label htmlFor='country'>country</label>
								<Dropdown
									calloutProps={{
										directionalHint: DirectionalHint.bottomLeftEdge,
										directionalHintFixed: true,
									}}
									options={countries.map((o) => {
										return {
											key: o.code,
											text: o.name,
										}
									})}
									onChange={(ev, option) => {
										if (handleOnChange) handleOnChange('country', option.key)
									}}
									type='text'
									name='country'
									placeholder='SELECT LOCATION'
									styles={{ title: { minHeight: 41 } }}
								/>
							</div>
						</div>
						{has_message && (
							<div className='col-12'>
								<div className='form-input'>
									<TextField
										name='message'
										onChange={(ev, value) => {
											if (handleOnChange) handleOnChange('message', value)
										}}
										defaultValue={formState.message.value}
										label='Message'
										errorMessage={formState.message.error}
										multiline
										rows={6}
									/>
								</div>
							</div>
						)}

						{has_areas_of_interest && (
							<div className='col-12'>
								<div className='areas-of-interest'>
									<span>Areas of Interest</span>
									<Checkbox label='Fundflows' onChange={() => handleAreaChange('Fundflows')} />
									<Checkbox label='Research' onChange={() => handleAreaChange('Research')} />
									<Checkbox label='Company News' onChange={() => handleAreaChange('Company News')} />
									<Checkbox label='Venture News' onChange={() => handleAreaChange('Venture News')} />
								</div>
							</div>
						)}
						{!acknowledged && (
							<div className='col-12 form-footer'>
								<div className='notice'>
									<span>Please acknowledge CoinShares' privacy policy and disclaimer.</span>
								</div>
							</div>
						)}
						<div className='col-12 form-footer'>
							<button className='button' type='submit' disabled={!acknowledged} onClick={handleOnSubmit} style={{ alignSelf: 'flex-start' }}>
								Submit
							</button>

							<span className='d-flex' style={{ columnGap: 15 }}>
								<Checkbox name='acknowledge' className={'form-term'} onChange={(ev, checked) => setAcknowledged(checked)} />
								<label htmlFor='acknowledge'>
									I acknowledge CoinShares'{' '}
									<a href={`/${privacy_policy?.cached_url}`} target='_blank' rel='noreferrer'>
										privacy policy
									</a>{' '}
									and{' '}
									<a href={`/${disclaimer?.cached_url}`} target='_blank' rel='noreferrer'>
										disclaimer
									</a>
								</label>
							</span>
						</div>
					</div>

					{/* </div> */}
				</form>
			)}

			{showConfirmation && formSrc && (
				<form className='contact-form'>
					{header && <h5>{header}</h5>}
					{confirmation_title && <h3 className='content'>{confirmation_title}</h3>}

					{confirmation_description && <RichText inline={true} blok={confirmation_description} />}

					<iframe frameBorder={0} opacity={0} src={formSrc} title={formSrc} width='1' height='1'></iframe>
				</form>
			)}
		</>
	)
}

export default ContactForm
