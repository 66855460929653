import React from 'react'

import { storyblokEditable, StoryblokComponent } from '@storyblok/react'

export const Row = ({ blok }) => {
	const { anchor_key, background } = blok

	return (
		<div className={background.toLowerCase()} id={anchor_key}>
			<div {...storyblokEditable(blok)}>
				<div className='container'>
					<div className='row'>
						{blok.columns.map((blok) => (
							<StoryblokComponent blok={blok} key={blok._uid} />
						))}
					</div>
				</div>
			</div>
		</div>
	)
}

export default Row
