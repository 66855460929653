import React, { useMemo, useState, useEffect } from 'react'
import { useSearchParams } from 'react-router-dom'
import { useQuery, gql } from '@apollo/client'
import KnowledgeByte from './KnowledgeByte'
import { getCookie } from '../PopupNotice'

export const KnowledgeBytesTopic = ({ blok, language }) => {
	const [searchParams] = useSearchParams()

	const [viewedBytes, setViewedBytes] = useState([])

	const query = gql`
        {
            ArticleItems(${language !== 'en' ? `starts_with: "${language}/*",` : ''} per_page: 100, by_slugs: "knowledge-bytes/*", sort_by: "sort_by_date:desc", filter_query: { additional_topics: { in_array: "${searchParams.get('id')}" }}) {
                items {
                    content {
                        key
                        title
                        is_question_card
                        primary_topic {
                            content
                        }
                        additional_topics {
                            content
                        }
                        article_type {
                            content
                        }
                        media_type
                    }
                    uuid
                    full_slug
                }
                total
            }
            ContenttopicItems(${language !== 'en' ? `starts_with: "${language}/*",` : ''} by_uuids: "${searchParams.get('id')}") {
                items {
                    name
                    content {
                        title
                        icon {
                            filename
                        }
                        light_icon {
                            filename
                        }
                    }
                }
            }
        }
    `

	const { /*loading, error,*/ data } = useQuery(query)
	const { ArticleItems, ContenttopicItems } = data || {}

	const [title, icon, light_icon] = useMemo(() => {
		if (ContenttopicItems && ContenttopicItems.items.length > 0) {
			const item = ContenttopicItems.items[0].content
			return [item?.title, item?.icon, item?.light_icon]
		}

		return ['', undefined]
	}, [ContenttopicItems])

	const knowledgeBytes = useMemo(() => {
		if (ArticleItems) {
			return ArticleItems.items.flatMap((item) => {
				const itemTopicTitles = [...(item.content.additional_topics?.map((o) => o.content.title) || [])].filter((o) => o)

				let tags = ''
				if (itemTopicTitles.length === 1) tags = itemTopicTitles[0]
				else if (itemTopicTitles.length > 1) tags = `${title} + ${itemTopicTitles.length - 1} More`

				return {
					uuid: item.uuid,
					full_slug: item.full_slug,
					...item.content,
					tagIcon: icon,
					tagIconHover: item.content.is_question_card ? icon : light_icon,
					tags: tags === '' ? [] : [tags],
				}
			})
		}

		return []
	}, [ArticleItems, icon, light_icon, title])

	useEffect(() => {
		fetch(`${process.env.REACT_APP_API_URI}/CustomActions?ApiKey=${process.env.REACT_APP_API_KEY}&key=${getCookie('visitor_id882933') || '1'}&code=VIEWED-KB`, {
			method: 'GET',
			headers: {
				'Content-Type': 'application/json',
			},
		}).then((value) => {
			value.json().then((value) => {
				setViewedBytes(value)
			})
		})
	}, [])

	return (
		<div>
			<div className='container'>
				<div className='row'>
					<div className='col-lg-7 col-12' style={{ paddingBottom: 40 }}>
						{title && (
							<h1 style={{ display: 'flex', alignItems: 'center', columnGap: 20 }}>
								{light_icon && <img height={120} src={light_icon.filename} alt={light_icon.filename} />}
								{title}
							</h1>
						)}
					</div>
				</div>
			</div>

			<div className='white'>
				<div className='container'>
					<div className='row'>
						<div className='col'>
							<div className='knowledge-bytes topics'>
								<div className='bytes'>
									{knowledgeBytes.map((byte, i) => (
										<KnowledgeByte key={byte.uuid} byte={byte} backgroundImage={i} viewed={viewedBytes.findIndex((o) => o === byte.key) >= 0} />
									))}
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}

export default KnowledgeBytesTopic
