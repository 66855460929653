import React from 'react'
import { Helmet } from 'react-helmet'
import { StoryblokComponent, storyblokEditable } from '@storyblok/react'
import { useLocation } from 'react-router-dom'

const Page = ({ blok, language }) => {
	const { isin, name, index_key, title: pageTitle, metatags = {}, drop_suffix } = blok
    const { title, description, og_title, og_description, og_image } = metatags
    const location = useLocation()

	return (
		<>
			<Helmet>
				<title>{og_title || title || pageTitle || name || 'Home'}{drop_suffix ? '' : ' - CoinShares'}</title>
                <meta name="description" content={og_description || description || title || pageTitle || name}/>
                <meta property='og:title' content={og_title || title || name || pageTitle || 'Home'} />
                <meta property="og:type" content="website" />
                <meta property="og:url" content={window.location.origin + location.pathname} />
                <meta property='og:description' content={og_description || description || title || pageTitle || name} />
                <meta property="og:image" content={og_image ? og_image + '/m/' : '/images/announcements.png'} />
                <link rel="canonical" href={window.location.origin + location.pathname} />
			</Helmet>

			<div {...storyblokEditable(blok)}>{blok.body ? blok.body.map((blok) => <StoryblokComponent blok={blok} key={blok._uid} isin={isin} name={name || title} bloomberg={index_key} language={language} />) : null}</div>
		</>
	)
}

export default Page
