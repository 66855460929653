import React, { useEffect, useMemo } from 'react'
import { useLocation } from 'react-router-dom'
import languages from '../../constants/languages.json'

import { storyblokEditable, StoryblokComponent } from '@storyblok/react'
import { VideoScroll } from '../VideoScroll'
import { useStoryblok } from '../../hooks/useStoryblok'
import { useQuery, gql } from '@apollo/client'
import { useGlobalState } from '../../store'
import { Route, Routes } from 'react-router-dom'
import DownloadBox from './Download'

const Global = ({ blok }) => {
	const location = useLocation()
	const { dispatch } = useGlobalState()
	const { header, summary, footer } = blok

	const [slug, language, hash] = useMemo(() => {
		let paths = location.pathname.substring(1).split('/')
		let language = 'en'

		var hasLanguage = false

		for (let index = 0; index < languages.length; index++) {
			var element = languages[index]
			hasLanguage = element.hasOwnProperty(paths[0])

			if (hasLanguage) break
		}

		if (hasLanguage) {
			language = paths[0]
			paths = paths.slice(1)
		}

		// multiple language support future
		return [paths.join('/') || 'home', language, location.hash]
	}, [location])

	// const [story, setStory] = useState()

	const version = window.location.search.includes('_storyblok') ? 'draft' : 'published'

	const story = useStoryblok(slug, { version: version, fallback_lang: 'en', language: language, resolve_relations: 'portfolio_card.category,article.author,article.article_type,knowledge_bytes.topics' }, {})

	useEffect(() => {
		if (!hash) {
			window.scrollTo(0, 0)
		} else {
			const elementToScroll = document.getElementById(hash.replace('#', ''))
			if (!elementToScroll) return
			window.scrollTo({ top: elementToScroll.offsetTop, behavior: 'smooth' })
		}
	}, [story, hash])

	const query = gql`
    {
        KnowledgeBytes: ArticleItems(${language !== 'en' ? `starts_with: "${language}/*",` : ''} per_page: 100, by_slugs: "knowledge-bytes/*", sort_by: "sort_by_date:desc") {
			items {
				content {
					key
					title
					body
                    description
					is_question_card
					additional_topics {
						content
					}
					media_type
				}
				uuid
				full_slug
			}
			total
		}
        DatasourceEntries(datasource: "lookup-terms", dimension: "${language}", per_page: 1000) {
            items {
                name
                dimensionValue
                value
            }
        }
    }`

	const { /*loading, error,*/ data } = useQuery(query)

	useEffect(() => {
		if (data && data.DatasourceEntries && data.KnowledgeBytes) {
			dispatch({
				type: 'SET_LOOKUP_TERMS',
				payload: data.DatasourceEntries.items.flatMap((o) =>
					(o.dimensionValue !== null ? o.dimensionValue : o.value).split(',').map((val) => {
						return {
							key: o.name,
							value: val.trim(),
						}
					})
				),
			})
			if (data.KnowledgeBytes) {
				const knowledgeBytes = data.KnowledgeBytes.items.flatMap((item) => {
					// const itemTopics = [item.content.primary_topic.uuid, ...(item.content.additional_topics?.map((o) => o.uuid) || [])].filter((o) => o)
					const itemTopicTitles = [item.content.primary_topic?.content?.title, ...(item.content.additional_topics?.map((o) => o.content.title) || [])].filter((o) => o)

					let tags = ''
					if (itemTopicTitles.length === 1) tags = itemTopicTitles[0]
					else if (itemTopicTitles.length > 1) tags = `${itemTopicTitles[0]} + ${itemTopicTitles.length - 1} More`

					return {
						uuid: item.uuid,
						full_slug: item.full_slug,
						...item.content,
						tags: tags === '' ? [] : [tags],
					}
				})

				dispatch({ type: 'SET_KNOWLEDGE_BYTES', payload: knowledgeBytes })
			}
		}
	}, [data, dispatch])
	// console.log('story', story)
	// useEffect(() => {
	// 	const storyblokApi = getStoryblokApi()
	// 	storyblokApi
	// 		.get(`cdn/stories/${slug}`, { version: process.env.REACT_APP_VERSION, fallback_lang: 'en', language: language, resolve_relations: 'portfolio_card.category,article.author' })
	// 		.then((value) => {
	// 			setStory(value.data.story)
	// 			window.scrollTo(0, 0)
	// 		})
	// 		.catch((e) => {
	// 			storyblokApi.get(`cdn/stories/not-found`, { version: process.env.REACT_APP_VERSION, fallback_lang: 'en', language: language }).then((value) => {
	// 				setStory(value.data.story)
	// 				window.scrollTo({ top: 0, left: 0, behavior: 'instant'})
	// 			})
	// 		})
	// }, [slug, language])

	if (!story || !story.content) {
		return <div></div>
	}

	return (
		<>
			<div {...storyblokEditable(blok)} className='global'>
				<VideoScroll videoUuid={story.content.background_animation || story.background_animation} scrollingElement={document.querySelector('#root')} />

				{header.map((blok) => (
					<StoryblokComponent blok={blok} key={blok._uid} slug={slug} language={language} footer={footer} />
				))}

				{summary.map((blok) => (
					<StoryblokComponent blok={blok} key={blok._uid} />
				))}

				<Routes>
					<Route exact path='/box/download/:key' element={<DownloadBox />} />
					<Route path='*' element={<>{location.pathname.indexOf('ui-kit-pages/layout') < 0 && <StoryblokComponent blok={{ ...story.content, name: story.name, first_published_at: story.first_published_at }} slug={slug} language={language} />}</>} />
				</Routes>

				{footer.map((blok) => (
					<StoryblokComponent blok={blok} key={blok._uid} />
				))}
			</div>
		</>
	)
}

export default Global
