import React, { useMemo } from 'react'
import { gql, useQuery } from '@apollo/client'
import { storyblokEditable, StoryblokComponent } from '@storyblok/react'
import { Scrollbar } from '../Scrollbar'
import BoxSet from './BoxSet'

// const boxSets = [
// 	{
// 		key: '2',
// 		title: 'Defi',
// 		background: '/images/boxset 2.png',
// 	},
// 	{
// 		key: '3',
// 		title: 'Mining',
// 		background: '/images/boxset 3.png',
// 	},
// 	{
// 		key: '4',
// 		title: 'Crypto Regulations',
// 		background: '/images/boxset 4.png',
// 	},
// ]

export const BoxSets = ({ blok, language }) => {
	const { anchor_key, title, background, description, call_to_action, layout } = blok

	const query = gql`
	{
		PageItems(${language !== 'en' ? `starts_with: "${language}/*",` : ''} by_slugs: "boxsets/*") {
            items {
                content {
                    description
                    image {
                        filename
                        title
                        alt
                    }
                }
                full_slug
                name
                id
              }
		}
	}
`

	const { /*loading, error,*/ data } = useQuery(query)
	const { PageItems } = data || {}

	const boxSets = useMemo(() => {
		if (PageItems) {
			return PageItems.items.flatMap((item) => {
				return { key: item.id, full_slug: item.full_slug, title: item.name, ...item.content }
			})
		}

		return []
	}, [PageItems])

	return (
		<div className={background.toLowerCase()} id={anchor_key}>
			<div {...storyblokEditable(blok)} className='container'>
				<div className={`row box-sets`}>
					{/* home */}
					{layout === 'layout_1' && (
						<>
							<div className='col-12 col-lg-8'>
								<h2>{title}</h2>
								<p className='body-intro'>{description}</p>
							</div>
							<div className='col-12'>
								<div className={`boxsets-container row ${layout}`} style={{ paddingTop: 40 }}>
									<div className='col'>
										<Scrollbar>
											<div className='boxsets-list'>
												{boxSets.map((set) => (
													<BoxSet key={set.key} set={set} />
												))}
											</div>
										</Scrollbar>
									</div>
								</div>
							</div>
							<div className='col-12'>
								{call_to_action.map((blok) => (
									<StoryblokComponent blok={blok} key={blok._uid} />
								))}
							</div>
						</>
					)}

					{/* knowledge */}
					{layout === 'layout_2' && (
						<>
							<div className='col-12 col-lg-7 offset-lg-4'>
								<h2>{title}</h2>
								<p className='body-intro'>{description}</p>
							</div>
							<div className='col-12'>
								<div className={`boxsets-container row ${layout}`} style={{paddingTop: 60}}>
									<div className='col'>
										<Scrollbar>
											<div className='boxsets-list'>
												{boxSets.map((set) => (
													<BoxSet key={set.key} set={set} />
												))}
											</div>
										</Scrollbar>
									</div>
								</div>
							</div>
						</>
					)}

					{/* boxset */}
					{layout === 'layout_3' && (
						<>
							<div className='col-lg-4 col-12 '>
								<div className='boxset-info'>
									<h2>{title}</h2>
									<p className='body-intro'>{description}</p>
									<div className='actions'>
										{call_to_action.map((blok) => (
											<StoryblokComponent blok={blok} key={blok._uid} />
										))}
									</div>
								</div>
							</div>
							<div className='col-lg-8 col-12'>
								<div className={`boxsets-container row ${layout}`}>
									<div className='col'>
										<Scrollbar>
											<div className='boxsets-list'>
												{boxSets.map((set) => (
													<BoxSet key={set.key} set={set} />
												))}
											</div>
										</Scrollbar>
									</div>
								</div>
							</div>
						</>
					)}
				</div>
			</div>
		</div>
	)
}

export default BoxSets
