import React from 'react'

import { storyblokEditable } from '@storyblok/react'

const PortfolioCard = ({ blok, onSelectBlok }) => {
	return (
		<div {...storyblokEditable(blok)} className='portfolio-card' onClick={() => {
            if (blok.popup_description && blok.popup_header && blok.popup_link) {
                onSelectBlok(blok)
            }
        }}>
			<img className='portfolio-img' src={blok.logo.filename} alt={blok.alt} />
			<span className='portfolio-title'>{blok.company_name}</span>
			<p className='portfolio-item'>{blok.description}</p>
		</div>
	)
}

export default PortfolioCard
