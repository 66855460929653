import React, { useEffect, useState, useRef } from 'react'
import * as d3 from 'd3'
import { useD3 } from '../../hooks/useD3'
import orderBy from 'lodash.orderby'

const height = 300
const width = 500
const margin = { top: 0, right: 0, bottom: 0, left: 0 }

export const Index = ({ indice }) => {
	const { index_key, card_series_suffix } = indice
	// const bloomberg = ''
	const [series, setSeries] = useState()

	const graphContainerRef = useRef()
	const ref = useD3(
		(svg) => {
			if (series && graphContainerRef) {
				const baseSeries = series.find((series) => series.key === index_key)
				const isYNumber = baseSeries?.scaleY === 'CURRENCY' || 'PERCENT'
				const prefix = baseSeries?.prefixY || ''
                const suffix = baseSeries?.suffixY || ''

				const data = orderBy(
					series.flatMap((o) =>
						o.dataX.flatMap((c, i) => {
							return {
								x: d3.timeParse('%Y/%m/%d')(c) || new Date(c),
								y: isYNumber ? +o.dataY[i] : o.dataY[i],
								series: o.key,
							}
						})
					),
					['series', 'x'],
					['asc', 'asc']
				)

				var xExtent = d3.extent(data, (d) => d.x)

				const xScale = d3
					.scaleTime()
					.domain(xExtent)
					.range([0, width - margin.left])

				const yMaxValue = d3.max(data, (d) => d.y)

				const yScale = d3
					.scaleLinear()
					.range([height - margin.bottom, 0])
					.domain([0, yMaxValue])
					.nice(5)

				const sumstat = d3.group(data, (d) => d.series)

				// y axis
				const yAxis = d3
					.axisLeft()
					.scale(yScale)
					.ticks(5)
					.tickSizeInner(0)
					.tickSizeOuter(-1 * (width - margin.left))

				svg.select('.y-axis').attr('transform', `translate(${margin.left}, 0)`).call(yAxis)
				svg.select('.y-axis .tick:first-of-type line').remove()

				svg.selectAll('.y-axis .tick line').attr('x2', width).attr('stroke-dasharray', 2)

				// draw tooltip hover
				const focus = svg.selectAll('.tooltip-line').attr('opacity', 0)
				const focusText = d3.select(graphContainerRef.current).selectAll('.tooltip').attr('opacity', 0)

				// draw series lines
				svg.select('.plot-area')
					.attr('transform', `translate(${margin.left}, 0)`)
					// .attr('width', width - margin.left)
					.selectAll('path')
					.data(sumstat)
					.join('path')
					.attr('fill', 'none')
					.attr('stroke-width', (d) => series.find((o) => o.key === d[0])?.lineSize)
					.attr('stroke', (d) => series.find((o) => o.key === d[0])?.colour || 'white')
					.attr('d', (d) => {
						return d3
							.line()
							.x((d) => xScale(d.x))
							.y((d) => yScale(d.y))(d[1])
					})

				svg.selectAll('.hover-container').remove()

				svg.append('rect')
					.classed('hover-container', true)
					.style('fill', 'none')
					.attr('transform', `translate(${margin.left}, 0)`)
					.style('pointer-events', 'all')
					.attr('width', width - margin.left)
					.attr('height', height)
					.on('mouseover', mouseover)
					.on('mousemove', mousemove)
					.on('mouseout', mouseout)

				function mouseover() {
					focus.style('opacity', 1)
					focusText.style('opacity', 1)
				}

				function mousemove(ev) {
					// recover coordinate we need
					const pointerPosition = d3.pointer(ev)
					var x0 = xScale.invert(pointerPosition[0])
					var selectedData =
						data[
							d3.bisectLeft(
								data.map((o) => o.x),
								x0
							)
						]

					if (selectedData) {
						const tooltipX = xScale(selectedData.x) + margin.left
						const tooltipY = yScale(selectedData.y)
						focus.attr('transform', `translate(${tooltipX}, 0)`) //.attr('cy', yScale(selectedData.y))

						const xPosition = tooltipX / (width - margin.left)
						const boundingBox = svg.node().getBoundingClientRect()
						const ratio = boundingBox.width / width
						const leftMargin = margin.left * ratio
						const focusBoundingBox = focusText.node().getBoundingClientRect()

						const tooltipPosition = {
							x: (boundingBox.width - leftMargin) * xPosition,
							y: tooltipY * ratio,
						}

						focusText.style('left', (tooltipPosition.x > boundingBox.width - focusBoundingBox.width ? tooltipPosition.x - focusBoundingBox.width : tooltipPosition.x) + 'px').style('top', tooltipPosition.y + 'px')

						const yValue = parseFloat(parseFloat(selectedData.y).toFixed(2)).toLocaleString('en-US')
						focusText.select('.tooltip-x').html(d3.utcFormat('%Y-%m-%d')(selectedData.x))
						focusText.select('.tooltip-y').html(`${prefix}${yValue}${suffix}`)
					}
				}
				function mouseout() {
					focus.style('opacity', 0)
					focusText.style('opacity', 0)
				}
			}
		},
		[series, graphContainerRef]
	)

	useEffect(() => {
		fetch(`${process.env.REACT_APP_API_URI}/Widgets?ApiKey=${process.env.REACT_APP_API_KEY}&names=INDEX_GRAPH_${index_key}${card_series_suffix !== null && card_series_suffix ? card_series_suffix : ''}`, {
			method: 'GET',
			headers: {
				'Content-Type': 'application/json',
			},
		}).then((value) => {
			value.json().then((value) => {
				const widget = value.find((val) => val.key === `GRAPH_WIDGET_${index_key}`)
				const section = widget.sections.find((section) => section.key === `GRAPH_SECTION_${index_key}`)
				const { graph } = section
				const { series } = graph

				setSeries(series)
			})
		})
	}, [index_key, card_series_suffix])

	return (
		<a className='indice' href={'/' + indice.full_slug}>
			{/* <img className='indice-img' src='/images/Indice.svg' alt={indice.content.title} />
			<img className='indice-img inverted' src='/images/IndiceInverted.svg' alt={indice.content.title} /> */}
			<div ref={graphContainerRef} className='line-graph-container'>
				<svg ref={ref} className='line-graph' viewBox={`0 0 ${width} ${height}`} preserveAspectRatio='xMinYMin meet'>
					<g className='x-axis'>
						<line x1={0} x2={width - margin.left} strokeDasharray={2} strokeWidth={1} stroke={'white'} />
					</g>
					<g className='y-axis' />
					<g className='plot-area' />
					<g className='tooltip-line'>
						<line x1={0} x2={0} strokeWidth={1} y1={margin.top} y2={height+margin.top} stroke={'#7DC7F5'} />
					</g>
				</svg>
				<div className='tooltip'>
					<div className='tooltip-x' />
					<div className='tooltip-y' />
				</div>
			</div>
			<h5>{indice.content.title}</h5>
			<div className='tag'>coinshares</div>

			<div className='indice-header'>Bloomberg: {indice.content.bloomberg}</div>
			<div className='indice-description'>{indice.content.description}</div>
			<div className='indice-header'>
			{indice.content.performance_percentage_header}: <span className='indice-content'>{indice.performance_percentage}%</span>
			</div>
			<div className='more-info' />
		</a>
	)
}

export default Index
