import React from 'react'
import { useMemo } from 'react'
import { Link } from 'react-router-dom'
import ForwardChevron from '../../../icons/ForwardChevron.svg'

const opt = '/m/'
const nth = function (d) {
	if (d > 3 && d < 21) return 'th'
	switch (d % 10) {
		case 1:
			return 'st'
		case 2:
			return 'nd'
		case 3:
			return 'rd'
		default:
			return 'th'
	}
}

export const Article = ({ article, list_view, restrainHeight }) => {
	const { img, datePublished, author, title, content, tags, full_slug, articleTypeName } = article

	const date = useMemo(() => {
		if (!!Date.parse(datePublished)) {
			const value = new Date(datePublished)

			return `${value.getDate()}${nth(value.getDate())} ${value.toLocaleString('default', { month: 'short' })} ${value.getFullYear()}`
		}

		return datePublished
	}, [datePublished])

	return (
		<Link className={'article' + (restrainHeight ? ' restrain' : '')} to={full_slug || '/'}>
			{img && (
				<div className='article-img'>
					<img src={`${img}${opt}`} alt='article' />
				</div>
			)}
			{(date || author) && (
				<div className='article-meta'>
					<span>{date}</span>
					{author && !list_view && <span>{author}</span>}
				</div>
			)}

			<h4 className='article title'>
				<div className='article-title-header'>Related article</div>
				<span>{title}</span>

				{content && <div className='article-content'><span>{content}</span></div>}
				{!list_view && <img className='article-forward' src={ForwardChevron} alt='back' />}
			</h4>

			<img className='article-forward' src={ForwardChevron} alt='back' />
			<div className='article-tags'>
				{tags &&
					tags.map((tag, index) => (
						<span className='tag' key={`${tag}${index}`}>
							{tag}
						</span>
					))}
			</div>

			{list_view && <div className='article-type'>{articleTypeName}</div>}
		</Link>
	)
}

export default Article
