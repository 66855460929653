import './App.css'
import { useStoryblok, StoryblokComponent } from '@storyblok/react'
import React, { useMemo } from 'react'
import { useLocation } from 'react-router-dom'
import languages from './constants/languages.json'
import { Helmet } from 'react-helmet'
import { StateContextProvider } from './store'

function App() {
	const location = useLocation()

	const [language] = useMemo(() => {
		let paths = location.pathname.substring(1).split('/')
		let language = 'en'

		var hasLanguage = false

		for (let index = 0; index < languages.length; index++) {
			var element = languages[index]
			hasLanguage = element.hasOwnProperty(paths[0])

			if (hasLanguage) break
		}

		if (hasLanguage) {
			language = paths[0]
		}

		// multiple language support future
		return [language]
	}, [location.pathname])

	const story = useStoryblok('ui-kit-pages/layout', { version: 'published', fallback_lang: 'en', language: language })

	if (!story || !story.content) {
		return <div></div>
	}

	// If story is there, use StoryblokComponent to render it (dynamically)
	return (
		<>
			<Helmet>
				<title>CoinShares</title>
			</Helmet>
			<StateContextProvider>
				<StoryblokComponent blok={story.content} />
			</StateContextProvider>
		</>
	)
}

export default App
