import React from 'react'
import { storyblokEditable, StoryblokComponent } from '@storyblok/react'

export const BoxsetRow = ({ blok }) => {
	const { background = 'transparent', anchor_key, body, title, description, index } = blok
	return (
		<div className={background.toLowerCase()} id={anchor_key}>
			<div {...storyblokEditable(blok)} className='widget'>
				<div className='container'>
					<div className='row'>
						<div className='col'>
							<div className={`boxset-row ${index ? 'border-left' : 'border-top'}`}>
								<div className='boxset-column'>
									{index && <h5>{index.padStart(2, '0')}</h5>}
									{title && <h2 className='content'>{title}</h2>}
									{description && (
										<p style={{ margin: 0 }} className='body-intro'>
											{description}
										</p>
									)}
								</div>
								<div className='boxset-rows'>
									{body.map((blok) => (
										<div className='boxset-row-item' key={blok._uid}>
											<StoryblokComponent blok={blok} inline={true} />
										</div>
									))}
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}

export default BoxsetRow
