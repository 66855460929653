import React from 'react'

import { storyblokEditable, StoryblokComponent } from '@storyblok/react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const ProfileCard = ({ blok, onExpandBio, bioExpanded, dimProfile }) => {
    const { biography, social_media } = blok

	return (
		<div className={`col-xl-${blok.width.xlarge} col-lg-${blok.width.large} col-md-${blok.width.medium} col-sm-${blok.width.small} col-${blok.width.xsmall}`}>
			<div {...storyblokEditable(blok)} className={`profile-card ${dimProfile && !bioExpanded ? 'dim' : ''} ${biography ? 'has-biography' : ''}`}  onClick={() => biography ? onExpandBio(blok._uid) : undefined}>
				<div className='profile-img-container'>
					<img src={blok.photo !== '' ? blok.photo.filename : ''} alt={blok.fullname} />
				</div>
				<div className='profile-details'>
					<div className='profile-name'>
						<span
							style={{
								fontWeight: 600,
								paddingBottom: 6,
								fontSize: 18,
							}}
						>
							{blok.fullname}
						</span>
						<span
							style={{
								fontSize: 15,
								fontWeight: 400,
							}}
						>
							{blok.title}
						</span>
					</div>

					<div className='social-media'>
						{social_media.map((blok) => (
							<StoryblokComponent blok={blok} key={blok._uid} />
						))}
					</div>
				</div>

				{blok.biography ? (
					<div className='bio'>
						<span className='bio-title'>
							<span style={{ paddingRight: 7 }}>BIO</span><FontAwesomeIcon className={`bio-close-icon ${bioExpanded ? 'expanded' : ''}`} icon={`fas fa-plus`} />
						</span>
						<div className='grid-line' />
					</div>
				) : (
					<div className='grid-line' />
				)}

				{bioExpanded && blok.biography && (
					<>
						<p className='biography'>{blok.biography}</p>
					</>
				)}
			</div>
		</div>
	)
}

export default ProfileCard
