import React, { useState, useCallback, useRef } from 'react'
import * as d3 from 'd3'
import { useD3 } from '../../hooks'
import orderBy from 'lodash.orderby'

const height = 480
const width = 720

const margin = { top: 8, right: 8, bottom: 45, left: 75 }

export const BoxsetRowContentGraphBarLine = ({ series, blokSeries, axisLabel }) => {
	const [selectedSeries, setSelectedSeries] = useState(blokSeries?.map((o) => o.identifier.toUpperCase()))

	const toggleSeries = useCallback((selectSeries) => {
		setSelectedSeries((selectedSeries) => {
			const newSelection = [...selectedSeries]

			const index = newSelection.indexOf(selectSeries)
			if (index >= 0) {
				newSelection.splice(index, 1)
			} else newSelection.push(selectSeries)

			return newSelection
		})
	}, [])

	const graphContainerRef = useRef()
	const ref = useD3(
		(svg) => {
			if (series && series.length > 0 && graphContainerRef) {
				const baseSeries = series[0]
				const isYNumber = baseSeries?.scaleY === 'CURRENCY' || 'PERCENT'
				const prefix = baseSeries?.prefixY || ''
				const suffix = baseSeries?.suffixY || ''

				let data = orderBy(
					series.flatMap((o) =>
						o.dataX.flatMap((c, i) => {
							return {
								x: d3.timeParse('%Y/%m/%d')(c) || new Date(c),
								y: isYNumber ? +o.dataY[i] : o.dataY[i],
								series: o.key,
								type: blokSeries.find((bs) => bs.identifier.toUpperCase() === o.key)?.type,
							}
						})
					),
					['series', 'x'],
					['asc', 'asc']
				)

				const minDate = data.find((o) => o.series === baseSeries.key)?.x
				data = data.filter((o) => o.x >= minDate)

				const xScale = d3
					.scaleBand()
					.domain(data.map((d) => d.x))
					.rangeRound([margin.left, width - margin.right])
					.padding(0.1)
                    .paddingOuter(0.1)

				const yScale = d3
					.scaleLinear()
					.domain([0, d3.max(data, (d) => d.y)])
					.rangeRound([height - margin.bottom, margin.top])
					.nice(5)

				const lineData = d3.group(
					data.filter((o) => o.type === 'line'),
					(d) => d.series
				)
				const barData = data.filter((o) => o.type === 'bar')

				const xAxis = d3
					.axisBottom()
					.scale(xScale)
					.tickPadding(20)
					.tickFormat((d) => d3.utcFormat("%b '%y")(d).toUpperCase())

				svg.select('.x-axis')
					.attr('transform', `translate(0, ${height - margin.bottom})`)
					.call(xAxis)

				svg.select('.x-axis .domain').remove()

				svg.selectAll('.x-axis .tick line').attr('y2', 0)
				svg.selectAll('.x-axis .tick text').attr('fill', '#122244').attr('font-weight', 'bold').attr('font-size', 15)

				// y axis
                svg.select('.y-axis-label').selectAll('text').remove()
				svg.select('.y-axis-label')
					.append('text')
					.attr('transform', 'rotate(-90)')
					.attr('y', 0)
					.attr('x', 0 - height / 2)
                    .attr('font-family', 'Roboto')
					.attr('dy', '1em')
                    .attr('stroke', '#122444')
					.style('text-anchor', 'middle')
					.text(axisLabel)

				const minorYAxis = d3
					.axisLeft()
					.scale(yScale)
					.ticks(49)
					.tickSizeInner(0)
					.tickSizeOuter(-1 * (width - margin.left - margin.right))
					.tickFormat('')

				svg.select('.y-axis-minor').attr('transform', `translate(${margin.left}, ${margin.top})`).call(minorYAxis)
				svg.select('.y-axis-minor .domain').remove()
				svg.selectAll('.y-axis-minor .tick line')
					.attr('x2', width - margin.left - margin.right)
					.attr('stroke', 'rgba(0, 0, 0, 0.1)')

				const yAxis = d3
					.axisLeft()
					.scale(yScale)
					.ticks(7)
					.tickSizeInner(0)
					.tickSizeOuter(-1 * (width - margin.left - margin.right))
					.tickPadding(12)
					.tickFormat((d) => `${prefix ? prefix + ' ' : ''}${d3.format(',.2f')(d)}${suffix ? ' ' + suffix : ''}`)

				svg.select('.y-axis').attr('transform', `translate(${margin.left}, ${margin.top})`).call(yAxis)

				svg.selectAll('.y-axis .tick text').attr('fill', '#122244').attr('font-weight', 'bold').attr('font-size', 15)

				svg.select('.y-axis .domain').remove()
				svg.selectAll('.y-axis .tick line')
					.attr('x2', width - margin.left - margin.right)
					.attr('stroke', '#122444')

				// draw bars
				svg.selectAll('.plot-area')
					.attr('transform', `translate(0, ${margin.top})`)

				svg.selectAll('.plot-area .bars')
					.selectAll('rect')
					.data(barData)
					.join('rect')
					.classed('disabled', (d) => selectedSeries.findIndex((o) => o === d.series) < 0)
					.attr('x', (d) => xScale(d.x))
					.attr('width', xScale.bandwidth())
					.attr('y', height - margin.bottom)
					.attr('height', 0)
					.attr('fill', (d) => series.find((o) => o.key === d.series)?.colour || '#122444')
					.transition()
					.duration(500)
					.ease(d3.easeLinear)
					.attr('y', (d) => yScale(d.y))
					.attr('height', (d) => yScale(0) - yScale(d.y))

				// draw series lines and circle plots
				svg.selectAll('.plot-area .plots')
					.attr('transform', `translate(${xScale.bandwidth() / 2}, 0)`)
					.selectAll('circle')
					.data(data.filter((o) => o.type === 'line'))
					.join('circle')
					.classed('disabled', (d) => selectedSeries.findIndex((o) => o === d.series) < 0)
					.attr('cx', (d) => xScale(d.x))
					.attr('cy', (d) => yScale(d.y))
					.attr('r', (d) => series.find((o) => o.key === d.series)?.lineSize * 3)
					.style('fill', (d) => series.find((o) => o.key === d.series)?.colour || '#122444')

				const lines = svg
					.select('.plot-area .lines')
					.attr('transform', `translate(${xScale.bandwidth() / 2}, 0)`)
					.selectAll('path')
					.data(lineData)
					.join('path')
					.classed('disabled', (d) => selectedSeries.findIndex((o) => o === d[0]) < 0)
					.attr('fill', 'none')
					.attr('stroke-width', (d) => series.find((o) => o.key === d[0])?.lineSize)
					.attr('stroke', (d) => series.find((o) => o.key === d[0])?.colour || '#122444')
					.attr('d', (d) => {
						return d3
							.line()
							.x((d) => xScale(d.x))
							.y((d) => yScale(d.y))(d[1])
					})

				// animate series
				const nodes = lines.nodes()
				for (let index = 0; index < nodes.length; index++) {
					const element = nodes[index]

					const totalLength = element.getTotalLength()
					d3.select(element)
						.attr('stroke-dasharray', totalLength + ' ' + totalLength)
						.attr('stroke-dashoffset', totalLength)
						.transition()
						.duration(1500)
						.ease(d3.easeLinear)
						.attr('stroke-dashoffset', 0)
				}
			}
		},
		[series, selectedSeries, axisLabel]
	)

	return (
		<div>
			<div ref={graphContainerRef} className='barline-graph-container series'>
				<svg ref={ref} className='barline-graph' viewBox={`0 0 ${width} ${height}`} preserveAspectRatio='xMinYMin meet'>
					<g>
						<rect width={width - margin.left - margin.right} height={height - margin.bottom - margin.top} x={margin.left} fillOpacity={0} y={margin.top} stroke={'white'} opacity={0.5} />
					</g>
					<g className='x-axis' />
					<g className='y-axis-label'></g>
					<g className='y-axis-minor' />
					<g className='y-axis' />
					<g className='plot-area'>
						<g className='bars'></g>
						<g className='lines'></g>
						<g className='plots'></g>
					</g>
				</svg>
			</div>
			<div className='legend circle'>
				{series &&
					series.length > 0 &&
					blokSeries &&
					blokSeries.map((o) => {
						const currentSeries = series.find((s) => s.key === o.identifier.toUpperCase())
						return (
							<div key={o.identifier} className={`legend-item ${selectedSeries.indexOf(o.identifier.toUpperCase()) >= 0 ? '' : 'disabled'}`} onClick={() => toggleSeries(o.identifier.toUpperCase())}>
								<span style={{ background: currentSeries?.colour }} className='legend-indicator' />
								<span className='legend-text'>
									{o.name} <img src='/images/visibility.svg' alt='' />
								</span>
							</div>
						)
					})}
			</div>
		</div>
	)
}

export default BoxsetRowContentGraphBarLine
