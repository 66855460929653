import React, { createContext, useReducer, useContext } from 'react'


const initialState = {
    knowledgeBytes: [],
	lookupTerms: [],
}

let reducer = (state, action) => {
	switch (action.type) {
		case 'SET_LOOKUP_TERMS': {
            return {
				...state,
                lookupTerms: action.payload
			}
		}

        case 'SET_KNOWLEDGE_BYTES': {
            return {
				...state,
                knowledgeBytes: action.payload
			}
		}

		default:
			return state
	}
}

const StateContext = createContext(initialState)

export const StateContextProvider = ({ children }) => {
	const [state, dispatch] = useReducer(reducer, initialState)

	return <StateContext.Provider value={{ ...state, dispatch }}>{children}</StateContext.Provider>
}

export const useGlobalState = () => useContext(StateContext)

