import React from 'react'

import { storyblokEditable } from '@storyblok/react'

const TimeCard = ({ blok }) => {
	return (
		<div {...storyblokEditable(blok)} className='time-card'>
			<span className="time-card-year">
                {blok.year}
            </span>

            <h4 className="time-card-title">
                {blok.title}
            </h4>

            <p className="time-card-description">
                {blok.description}
            </p>
		</div>
	)
}

export default TimeCard
