import React from 'react'

import { storyblokEditable } from '@storyblok/react'
import { Link } from 'react-router-dom'
import ChevronLarge from '../../icons/ChevronLarge.svg'

export const BoxSet = ({ blok, set }) => {
	const { title, description, image, full_slug } = set

	return (
		<Link to={full_slug} style={{ backgroundImage: `url('${image.filename}')` }} {...storyblokEditable(blok)} className={'boxset'}>
			<h4 className='boxset-title'>{title}</h4>

			{/* <div className='boxset'>{tags.map(tag => <span key={tag} className='tag'>{tag}</span>)}</div> */}
			<p className='boxset-description' style={{ zIndex: 1 }}>
				{description}
			</p>
			<div className='boxset-forward'>
				<img src={ChevronLarge} alt='forward' />
			</div>
		</Link>
	)
}

export default BoxSet
