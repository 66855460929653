import { gql, useQuery } from '@apollo/client'
import React from 'react'
import { Modal, Dropdown, DirectionalHint } from '@fluentui/react'
import { useState } from 'react'
import { useMemo } from 'react'
import { useCallback } from 'react'
import { useEffect } from 'react'

const setCookie = (cname, cvalue, exdays) => {
	const d = new Date()
	d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000)
	let expires = 'expires=' + d.toUTCString()
	document.cookie = cname + '=' + cvalue + ';' + expires + '; path=/'
}

export const getCookie = (cname) => {
	let name = cname + '='
	let decodedCookie = decodeURIComponent(document.cookie)
	let ca = decodedCookie.split(';')
	for (let i = 0; i < ca.length; i++) {
		let c = ca[i]
		while (c.charAt(0) === ' ') {
			c = c.substring(1)
		}
		if (c.indexOf(name) === 0) {
			return c.substring(name.length, c.length)
		}
	}
	return ''
}

export const PopupNotice = ({ blok, language }) => {
	const { cookie_expiration_days = 90 } = blok

	const query = gql`
	{
		PopupnoticeItems${language !== 'en' ? `(starts_with: "${language}/*")` : ''} {
			items {
				content {
					title
					missing_notice_text
					location_header
					investor_type_header
					description
					confirmation_text
					confirmation_link_name
					confirm_button_title
				}
			}
		}
		CountryItems {
			items {
				uuid
				name
				slug
			}
			total
		}
		InvestortypeItems {
			items {
				uuid
				name
				slug
			}
			total
		}
		LegalnoticeItems {
			items {
				full_slug
                slug
				name
				uuid
			}
			total
		}
	}
`

	const { data } = useQuery(query)
	const { CountryItems, InvestortypeItems, LegalnoticeItems, PopupnoticeItems } = data || {}
	const [showPopup, setShowPopup] = useState(false)
	const [selectedCountry, setSelectedCountry] = useState()
	const [selectedInvestorType, setSelectedInvestorType] = useState()

	const [countries, investorTypes, legalNotices, popupNotice] = useMemo(() => {
		return [CountryItems?.items || [], InvestortypeItems?.items || [], LegalnoticeItems?.items || [], PopupnoticeItems?.items[0].content]
	}, [CountryItems, InvestortypeItems, LegalnoticeItems, PopupnoticeItems])

	const legalNotice = useMemo(() => {
		if (!selectedCountry || !selectedInvestorType) return undefined

		return legalNotices.find((o) => o.slug === `${selectedInvestorType}-${selectedCountry}`)
	}, [selectedCountry, selectedInvestorType, legalNotices])

	const handleSubmit = useCallback(() => {
		setCookie('product-consent', true, cookie_expiration_days)
		setShowPopup(false)
	}, [cookie_expiration_days])

	useEffect(() => {
		if (!getCookie('product-consent')) {
			setShowPopup(true)
		}
	}, [])

	return (
		<Modal
			isOpen={showPopup}
			onDismiss={(ev) => {
				if (ev.target.classList.indexOf('ms-Overlay') >= 0) {
					ev.preventDefault()
					setShowPopup(false)
				}
			}}
			containerClassName={'popup-modal popup-notice'}
		>
			<div className='container modal-header'>
				<h2>{popupNotice?.title}</h2>
			</div>
			<div className='container'>
				<div className='row'>
					<div className='col-12'>
						<p>{popupNotice?.description}</p>
					</div>
				</div>
			</div>
			<div className='container'>
				<div className='row'>
					<div className='col-lg-6 col-12'>
						<Dropdown
							calloutProps={{
								directionalHint: DirectionalHint.bottomLeftEdge,
								directionalHintFixed: true,
							}}
							label={popupNotice?.investor_type_header}
							placeholder='Select Investor Type'
							options={investorTypes.map((c) => {
								return {
									key: c.slug,
									text: c.name,
								}
							})}
							onChange={(ev, option) => setSelectedInvestorType(option?.key)}
							selectedKey={selectedInvestorType}
						/>
					</div>
					<div className='col-lg-6 col-12'>
						<Dropdown
							calloutProps={{
								directionalHint: DirectionalHint.bottomLeftEdge,
								directionalHintFixed: true,
							}}
							label={popupNotice?.location_header}
							placeholder='Select Country'
							options={countries.map((c) => {
								return {
									key: c.slug,
									text: c.name,
								}
							})}
							onChange={(ev, option) => setSelectedCountry(option?.key)}
							selectedKey={selectedCountry}
						/>
					</div>
				</div>
			</div>

			{!legalNotice && selectedCountry && selectedInvestorType && (
				<div className='container'>
					<div className='row'>
						<div className='col-12'>
							<div className='notice'>
								<span>{popupNotice?.missing_notice_text}</span>
							</div>
						</div>
					</div>
				</div>
			)}

			{legalNotice && (
				<>
					<div className='container'>
						<div className='row'>
							<div className='col-12'>
								<p className='notice-disclaimer'>
									<span>{popupNotice?.confirmation_text}&nbsp;</span>
									<span>
										<a href={`/${legalNotice.full_slug}`} target='_blank' rel='noreferrer'>
											{popupNotice?.confirmation_link_name}
										</a>
									</span>
								</p>
							</div>
						</div>
					</div>

					<div className='container'>
						<div className='row'>
							<div className='col-12'>
								<button className='button' onClick={handleSubmit}>
									{popupNotice?.confirm_button_title}
								</button>
							</div>
						</div>
					</div>
				</>
			)}
		</Modal>
	)
}

export default PopupNotice
