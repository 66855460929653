import React, { useMemo, useState } from 'react'
import { useQuery, gql } from '@apollo/client'
import { RichText } from '../../RichText'
import ETP from '../../ETPList/ETP'
import { Article } from '../Article'
import { StoryblokComponent } from '@storyblok/react'

const nth = function (d) {
	if (d > 3 && d < 21) return 'th'
	switch (d % 10) {
		case 1:
			return 'st'
		case 2:
			return 'nd'
		case 3:
			return 'rd'
		default:
			return 'th'
	}
}

const getHeadings = (obj, array) => {
	if (obj) {
		const o = obj
		const content = o.content
		// check if key exists in array and change properties
		if (content && content.length > 0) {
			// check if has children
			content.forEach((v) => {
				// if has children do the same recursion for all children
				getHeadings(v, array)
			})
		}

		if (o.type === 'heading' && o.attrs.level === 2) {
			const text =
				o.content && o.content.length > 0
					? o.content
							.filter((text) => text.type === 'text')
							.map((text) => text.text)
							.join('')
					: ''
			array.push({ id: text.toLowerCase().replace(/ /g, '_'), text: text })
		}
	}
}

export const ArticleContent = ({ blok, language }) => {
	const { _uid, title: pageTitle, body, primary_topic, additional_topics, first_published_at, author, related_etps = [], owl } = blok
    const [showSideBar, setShowSideBar] = useState(false)
    const [showArticles, setShowArticles] = useState(true)
    const [showETPs, setShowETPs] = useState(true)
    
	const query = gql`
	{
		ExchangetradedproductItems(${language !== 'en' ? `starts_with: "${language}/*",` : ''} by_uuids: "${related_etps.length > 0 ? related_etps.join(',') : '1'}") {
			items {
				content {
					name
					isin
                    description
                    card_series_suffix
				}
				uuid
                full_slug
			}
		}
        ArticleItems (per_page: 3, sort_by: "sort_by_date:desc", filter_query:{ primary_topic: {in:"${primary_topic}"}}) {
			items {
				content {
                    _uid
					title
					image {
						filename
					}
                    primary_topic {
                        uuid
                        content
                    }
                    additional_topics {
						uuid
						content
					}
                    author {
						name
					}
				}
				uuid
				full_slug
				name
				first_published_at
			}
		}
        ContenttopicItems {
            items {
              content {
                title
              }
              uuid
            }
        }
	}
    `

	const { /*loading, error,*/ data } = useQuery(query)

	const { ContenttopicItems, ExchangetradedproductItems, ArticleItems } = data || {}

	const products = useMemo(() => {
		if (ExchangetradedproductItems) {
			return ExchangetradedproductItems.items.flatMap((item) => {
				return { uuid: item.uuid, full_slug: item.full_slug, ...item.content }
			})
		}

		return []
	}, [ExchangetradedproductItems])

	const topics = useMemo(() => {
		if (ContenttopicItems) {
			return ContenttopicItems.items.flatMap((item) => {
				return { uuid: item.uuid, ...item.content }
			})
		}

		return []
	}, [ContenttopicItems])

	const articles = useMemo(() => {
		if (ArticleItems) {
			return ArticleItems.items
				.flatMap((item) => {
                    const itemTopicTitles = [item.content.primary_topic?.content?.title, ...(item.content.additional_topics?.map((o) => o.content.title) || [])].filter((o) => o)
					return {
						uuid: item.uuid,
						full_slug: item.full_slug,
						datePublished: item.first_published_at,
						topics: [],
						articleType: '',
                        tags: itemTopicTitles,
						// topics: item.content.content_tagging.flatMap((content) => content).flatMap((item) => [item.topic]),
						// articleType: item.content.content_tagging.flatMap((content) => content).flatMap((item) => item.article_type),
						...item.content,
						author: item.content.author.name,
					}
				})
				.filter((o) => o._uid !== _uid)
		}

		return []
	}, [ArticleItems, _uid])

	const headers = useMemo(() => {
		let array = []
		getHeadings(body, array)

		return array
	}, [body])

	const date = useMemo(() => {
		if (!!Date.parse(first_published_at)) {
			const value = new Date(first_published_at)

			return `${value.getDate()}${nth(value.getDate())} ${value.toLocaleString('default', { month: 'short' })} ${value.getFullYear()}`
		}

		return first_published_at
	}, [first_published_at])

	const readTime = useMemo(() => Math.max(1, Math.floor((JSON.stringify(body || '').length * 0.7) / 6 / 230)), [body])

	return (
		<>
			<div className='white light '>
				<div className='container'>
					<div className='row'>
						<div className='article-container'>
							<div className='story-article-main'>
								<div className='story-article'>
									<div className='article-meta'>
										<span>{date}</span>
										<span>{readTime} min read</span>
										{author && <span>{author.name}</span>}
									</div>
									<h1 className='article'>{pageTitle}</h1>

									{(primary_topic || (additional_topics && additional_topics.length > 0)) && (
										<div className='article-tags'>
											{primary_topic && <span className='tag'>{topics.find((o) => o.uuid === primary_topic)?.title}</span>}
											{additional_topics && additional_topics.map((at) => <span className='tag'>{topics.find((o) => o.uuid === at)?.title}</span>)}
										</div>
									)}

									<div className='article-navigation'>
										{headers.map(({ id, text }, i) => (
											<a className='article-navigation-item' key={id} href={`#${id}`}>
												<span className='article-nav-index'>{(i + 1).toString().padStart(2, '0')}</span>
												<span className='article-nav-title'>{text}</span>
												<img src='/images/ChevronRightSmall.png' alt='chevron' />
											</a>
										))}
									</div>

									{/* {body.map((blok) => ( */}
									{body && <RichText blok={body} key={blok._uid} inline={true} content={true} stickyHeaders={true} />}
									{/* ))} */}
								</div>
							</div>
							<div className={`related-content${showSideBar ? ' is-open': ''}`}>
								<div className='related-content-sidebar'>
									<button className='action-button' onClick={() => setShowSideBar(show => !show)}>
                                        <span className='button-icon'>
											<img src='/images/ChevronLeftArticle.svg' alt='Open Related Content' />
										</span>
										<span className='rotate-content'>Related Content</span>
										
									</button>
								</div>
								<div className='related-content-content'>
									{primary_topic && (
										<>
											<div className='article-toggle'>
												<div className='toggle-header' onClick={() => setShowArticles(show => !show)}>
													<h5>More About {topics.find((o) => o.uuid === primary_topic)?.title}</h5>
													<span className='toggle-action'>{showArticles ? '-' : '+'}</span>
												</div>
											</div>
											{showArticles && <div className='articles knowledge layout_3'>
												{articles.map((article) => (
													<div className={'col-12'} key={article.uuid}>
														<Article
															article={{
																img: article.image.filename,
																author: 'James Butterfill',
																tags: [],
																// articleTypeName: article.articleType.filter((at) => at !== '').map((item) => articleTypes.find((t) => t.uuid === item)?.title),
																...article,
															}}
														/>
													</div>
												))}
											</div>}
										</>
									)}

									{related_etps.length > 0 && (
										<>
											<div className='article-toggle'>
												<div className='toggle-header' onClick={() => setShowETPs(show => !show)}>
													<h5>Related Products</h5>
													<span className='toggle-action'>{showETPs ? '-' : '+'}</span>
												</div>
											</div>
											{showETPs && <div className='products-list'>
												{products.map((product) => (
													<ETP product={product} />
												))}
											</div>}
										</>
									)}
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>

            {owl && owl.map(blok => <StoryblokComponent key={blok._uid} blok={blok} />)}
		</>
	)
}

export default ArticleContent
