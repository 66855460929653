import React, { useState, useMemo, useCallback } from 'react'
import { Dropdown, DirectionalHint } from '@fluentui/react'
import { useQuery, gql } from '@apollo/client'
import { Article } from '..'
import orderBy from 'lodash.orderby'
import { useEffect } from 'react'
import { unionBy } from 'lodash'
import * as d3 from 'd3'
import { useSearchParams, useNavigate } from 'react-router-dom'
import KnowledgeByte from '../KnowledgeBytes/KnowledgeByte'

// , resolve_relations: "content_tagging.topic"
// (filter_query: { type: { in: "announcement" } })

export const KnowledgeView = ({ blok, language }) => {
	const queryLookups = gql`
	{
		ContenttopicItems(${language !== 'en' ? `starts_with: "${language}/*",` : ''} per_page: 100) {
			items {
				content {
					title
				}
				uuid
			}
		}
		ArticletypeItems(${language !== 'en' ? `starts_with: "${language}/*",` : ''} per_page: 100) {
			items {
				content {
					title
				}
				uuid
			}
		}
	}
`
	const { anchor_key, hide_filters, title, index } = blok
	const [page, setPage] = useState(1)
	const [searchParams] = useSearchParams()
	const navigate = useNavigate()

	const [queryParams, setQueryParams] = useState([searchParams.get('sort') || 'desc', searchParams.get('topic') || 'all', searchParams.get('article_type') || 'all'])

	const [selectedSortBy, selectedTopic, selectedArticleType] = queryParams

	const filterQuery = useMemo(() => {
		const currentDate = d3.utcFormat('%Y-%m-%d %H:%M')(new Date())
		const filters = [`release_date: { lt_date: "${currentDate}" }`, `expiry_date: { gt_date: "${currentDate}" }`]

		if (selectedTopic !== 'all') {
			filters.push(`primary_topic: { in: "${selectedTopic}" }`)
		}
		if (selectedArticleType !== 'all') {
			filters.push(`article_type: { in: "${selectedArticleType}" }`)
		}

		return `, filter_query: {${filters.join(',')}}`
	}, [selectedArticleType, selectedTopic])

	const articleQuery = gql`
    {
        ArticleItems(${language !== 'en' ? `starts_with: "${language}/*",` : ''} page: ${page}, per_page: 20, sort_by: "sort_by_date:${selectedSortBy}"${filterQuery}) {
			items {
				content {
					title
					image {
						filename
					}
					primary_topic {
						uuid
						content
					}
					additional_topics {
						uuid
						content
					}
					article_type {
						uuid
                        slug
						content
					}
					author {
						name
					}
                    is_question_card
				}
				uuid
				full_slug
				name
				first_published_at
			}
			total
		}
    }`

	const { /*loading, error,*/ data: lookupsData } = useQuery(queryLookups)
	const { loading, /*error,*/ data } = useQuery(articleQuery)

	const { ContenttopicItems, ArticletypeItems } = lookupsData || {}
	const { ArticleItems } = data || {}
	const [selectedView, setSelectedView] = useState(hide_filters ? 'list' : 'non-list')
	const [total, setTotal] = useState(0)
	const [items, setItems] = useState([])

	const topics = useMemo(() => {
		if (ContenttopicItems) {
			return ContenttopicItems.items.flatMap((item) => {
				return { uuid: item.uuid, ...item.content }
			})
		}

		return []
	}, [ContenttopicItems])

	const articleTypes = useMemo(() => {
		if (ArticletypeItems) {
			return ArticletypeItems.items.flatMap((item) => {
				return { uuid: item.uuid, ...item.content }
			})
		}

		return []
	}, [ArticletypeItems])

	useEffect(() => {
		if (ArticleItems) {
			setItems((items) =>
				orderBy(
					unionBy(
						ArticleItems.items.flatMap((item) => {
							const itemTopics = [item.content.primary_topic.uuid, ...(item.content.additional_topics?.map((o) => o.uuid) || [])].filter((o) => o)
							const itemTopicTitles = [item.content.primary_topic?.content?.title, ...(item.content.additional_topics?.map((o) => o.content.title) || [])].filter((o) => o)

							return {
								uuid: item.uuid,
								full_slug: item.full_slug,
								datePublished: item.first_published_at,
								topics: itemTopics,
								tags: itemTopicTitles,
								articleType: item.content.article_type.uuid,
								isKnowledgeBytes: item.content.article_type?.slug === 'knowledge-bytes',
								...item.content,
								author: item.content.author.name,
							}
						}),
						items.flatMap((o, i) => {
							return {
								...o,
								index: i + (page - 1) * 20,
							}
						}),
						'uuid'
					),
					['index'],
					[selectedSortBy === 'asc' ? 'desc' : 'asc']
				)
			)

			setTotal(ArticleItems.total)
		}
	}, [ArticleItems, page, selectedSortBy])

	const onQueryParamsChange = useCallback(
		(sort_by, topic, article_type) => {
			const params = new URLSearchParams()
			if (sort_by || selectedSortBy) {
				params.append('sort', sort_by || selectedSortBy)
			}
			if ((article_type && article_type !== 'all') || (selectedArticleType && selectedArticleType !== 'all')) {
				params.append('article_type', article_type || selectedArticleType)
			}
			if ((topic && topic !== 'all') || (selectedTopic && selectedTopic !== 'all')) {
				params.append('topic', topic || selectedTopic)
			}

			setQueryParams([sort_by || selectedSortBy, topic || selectedTopic, article_type || selectedArticleType])
			navigate({ search: params.toString() })
		},
		[navigate, selectedSortBy, selectedArticleType, selectedTopic]
	)

	const selectableTopics = useMemo(() => {
		return [
			{ key: 'all', text: 'All' },
			...orderBy(
				topics.map((o) => {
					return {
						key: o.uuid,
						text: o.title,
					}
				}),
				['text'],
				['asc']
			),
		]
	}, [topics])

	const selectableArticleTypes = useMemo(() => {
		return [
			{ key: 'all', text: 'All' },
			...orderBy(
				articleTypes.map((o) => {
					return {
						key: o.uuid,
						text: o.title,
					}
				}),
				['text'],
				['asc']
			),
		]
	}, [articleTypes])

	return (
		<div className={'widget' + (hide_filters === true ? ' light' : '')} style={hide_filters === true ? { marginTop: 3 } : undefined} id={anchor_key}>
			{index ? (
				<div className='container'>
					<div className='row'>
						<div className='col'>
							<h5>{index.padStart(2, '0')}</h5>
						</div>
					</div>
				</div>
			) : undefined}
			{hide_filters === true ? undefined : (
				<div className='container'>
					<div className='row' style={{ paddingBottom: 60 }}>
						<div className='col-12 col-sm-6 col-lg-4 col-xl-3'>
							{/* setSelectedCurrency(option.key) */}
							<Dropdown
								calloutProps={{
									directionalHint: DirectionalHint.bottomLeftEdge,
									directionalHintFixed: true,
								}}
								label='TOPICS'
								options={selectableTopics}
								selectedKey={selectedTopic}
								onChange={(event, option) => {
									setPage(1)
									setItems([])
									onQueryParamsChange(undefined, option.key, undefined)
								}}
							/>
						</div>
						<div className='col-12 col-sm-6 col-lg-4 col-xl-3'>
							<Dropdown
								calloutProps={{
									directionalHint: DirectionalHint.bottomLeftEdge,
									directionalHintFixed: true,
								}}
								label='CONTENT TYPE'
								options={selectableArticleTypes}
								selectedKey={selectedArticleType}
								onChange={(event, option) => {
									setPage(1)
									setItems([])
									onQueryParamsChange(undefined, undefined, option.key)
								}}
							/>
						</div>
						<div className='col-12 col-sm-6 col-lg-4 col-xl-3'>
							<Dropdown
								calloutProps={{
									directionalHint: DirectionalHint.bottomLeftEdge,
									directionalHintFixed: true,
								}}
								label='SORTED BY'
								options={[
									{ key: 'desc', text: 'NEWEST' },
									{ key: 'asc', text: 'OLDEST' },
								]}
								selectedKey={selectedSortBy}
								onChange={(event, option) => {
									setPage(1)
									setItems([])
									onQueryParamsChange(option.key, undefined, undefined)
								}}
							/>
						</div>
					</div>
				</div>
			)}
			<div className='light'>
				<div className='container'>
					<div className='row articles knowledge layout_3' style={hide_filters ? { paddingTop: 40 } : undefined}>
						<div className='col-12' style={{ marginBottom: 100 }}>
							<div className='knowledgeview-header'>
								{title ? <h2 className='content'>{title}</h2> : <h5>Articles ({total})</h5>}

								<div className='knowledgeview-actions'>
									<button className={`toggle ${selectedView === 'list' ? 'selected' : ''}`} onClick={() => setSelectedView('list')}>
										<img className='toggle-white' src='/images/ListViewWhite.svg' alt='list view' />
										<img className='toggle-dark' src='/images/ListViewDark.svg' alt='list view' />
									</button>
									<button className={`toggle ${selectedView === 'non-list' ? 'selected' : ''}`} onClick={() => setSelectedView('non-list')}>
										<img className='toggle-white' src='/images/NonListViewWhite.svg' alt='non list view' />
										<img className='toggle-dark' src='/images/NonListViewDark.svg' alt='non list view' />
									</button>
								</div>
							</div>
						</div>

						{selectedView === 'list' && (
							<div className='list'>
								<div className='article list-header'>
									<span>Date</span>
									<span>Article</span>
									<span>Topics</span>
									<span>Content Type</span>
								</div>
							</div>
						)}
						{items.map((article) => (
							<div className={selectedView === 'list' ? 'list col-12' : 'col-12 col-sm-6 col-lg-4 col-xl-3'} key={article.uuid}>
								{(selectedView === 'list' || !article.isKnowledgeBytes) && (
									<Article
										list_view={selectedView === 'list'}
										article={{
											img: article.image.filename,
											articleTypeName: article.article_type?.content?.title,
											...article,
										}}
									/>
								)}

								{article.isKnowledgeBytes && selectedView !== 'list' && (
									<KnowledgeByte
										byte={{
											...article,
											header: article.article_type?.content?.title,
										}}
									/>
								)}
							</div>
						))}
					</div>

					<div className='row'>
						<div className='col'>
							<div className='article-load-more'>
								<span>
									Viewed {Math.min((items || []).length, total)} out of {total} articles
								</span>
								<div className='load-more-progress'>
									<div className='load-more-progress-indicator' style={{ width: (Math.min((items || []).length, total) / total) * 100 + '%' }} />
								</div>
								{total !== Math.min(page * 20, total) && (
									<button disabled={loading} className='button' onClick={() => setPage((page) => page + 1)}>
										Load More
									</button>
								)}
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}

export default KnowledgeView
