import React, { useRef } from 'react'
import * as d3 from 'd3'
import { useD3 } from '../../hooks'
import orderBy from 'lodash.orderby'

const miniHeight = 120
const height = 480 - miniHeight
const width = 720

const margin = { top: 8, right: 8, bottom: 60, left: 75 }

export const BoxsetRowContentGraphTimeSeries = ({ series, axisLabel, blokSeries }) => {
	const graphContainerRef = useRef()
	const ref = useD3(
		(svg) => {
			if (series && series.length > 0 && graphContainerRef) {
				const baseSeries = series[0]
				const isYNumber = baseSeries?.scaleY === 'CURRENCY' || 'PERCENT'
				const prefix = baseSeries?.prefixY || ''
				const suffix = baseSeries?.suffixY || ''

				let data = orderBy(
					series.flatMap((o) =>
						o.dataX.flatMap((c, i) => {
							return {
								x: d3.timeParse('%Y/%m/%d')(c) || new Date(c),
								y: isYNumber ? +o.dataY[i] : o.dataY[i],
								series: o.key,
								type: blokSeries.find((bs) => bs.identifier.replace('_', '').toUpperCase() === o.key)?.type,
							}
						})
					),
					['series', 'x'],
					['asc', 'asc']
				)

				const minDate = data.find((o) => o.series === baseSeries.key)?.x
				data = data.filter((o) => o.x >= minDate)

				var xExtent = d3.extent(
					data.filter((o) => o.series === baseSeries.key),
					(d) => d.x
				)

				const originalScale = d3
					.scaleTime()
					.domain(xExtent)
					.range([0, width - margin.left - margin.right])

				const xScale = d3
					.scaleTime()
					.domain(xExtent)
					.range([0, width - margin.left - margin.right])

				const xScaleBar = d3
					.scaleBand()
					.domain(data.map((d) => d.x))
					.range([0, width - margin.left - margin.right])
					.paddingInner(0.2)
					.paddingOuter(0.1)

				const yScale = d3
					.scaleLinear()
					.domain([0, d3.max(data, (d) => d.y)])
					.rangeRound([height - margin.bottom, margin.top])
					.nice(5)

				const yScale1 = d3
					.scaleLinear()
					.domain([0, d3.max(data, (d) => d.y)])
					.range([miniHeight, 0])
					.nice(5)

				const lineData = d3.group(
					data.filter((o) => o.type === 'line'),
					(d) => d.series
				)
				const barData = data.filter((o) => o.type === 'bar')

				// seek x axis
				const xSeek = d3
					.axisBottom()
					.scale(originalScale)
					.tickSize(miniHeight)
					.tickPadding(8)
					.ticks(d3.utcMonth.every(2))
					.tickFormat((d) => d3.utcFormat('%b %Y')(d))

				svg.select('.x-axis-seek').attr('transform', `translate(${margin.left}, ${height})`).call(xSeek)

				svg.select('.x-axis-seek .domain').attr('stroke', '#D9D9D9')
				svg.selectAll('.x-axis-seek .tick line').attr('stroke', '#D9D9D9')
				svg.selectAll('.x-axis-seek .tick text').attr('transform', 'translate(40, -25)').attr('fill', '#898888').attr('font-size', 12)

				// x axis
				const x = d3
					.axisBottom()
					.scale(xScale)
					.tickSize(8)
					.tickPadding(8)
					.ticks(d3.utcMonth.every(1))
					.tickFormat((d) => d3.utcFormat("%b '%y")(d))

				const xAxis = svg
					.select('.x-axis')
					.attr('transform', `translate(${margin.left}, ${height - margin.bottom})`)
					.call(x)

				svg.select('.x-axis .domain').remove()
				svg.selectAll('.x-axis .tick line').attr('stroke', '#D9D9D9')
				svg.selectAll('.x-axis .tick text').attr('fill', '#898888').attr('font-size', 12)

				// y axis
				svg.select('.y-axis-label').selectAll('text').remove()
				svg.select('.y-axis-label')
					.append('text')
					.attr('transform', 'rotate(-90)')
					.attr('y', 0)
					.attr('x', 0 - height / 2)
					.attr('font-family', 'Roboto')
					.attr('dy', '1em')
					.attr('stroke', '#122444')
					.style('text-anchor', 'middle')
					.text(axisLabel)

				const yAxis = d3
					.axisLeft()
					.scale(yScale)
					.ticks(5)
					.tickSizeInner(0)
					.tickSizeOuter(-1 * (width - margin.left - margin.right))
					.tickPadding(12)
					.tickFormat((d) => `${prefix ? prefix + ' ' : ''}${d3.format(',.2f')(d)}${suffix ? ' ' + suffix : ''}`)

				svg.select('.y-axis').attr('transform', `translate(${margin.left}, 0)`).call(yAxis)

				svg.selectAll('.y-axis .tick text').attr('fill', '#898888').attr('font-size', 12)

				svg.select('.y-axis .domain').remove()
				svg.selectAll('.y-axis .tick line')
					.attr('x2', width - margin.left - margin.right)
					.attr('stroke', '#D9D9D9')

				// draw bars
				const bars = svg
					.selectAll('.plot-area .bars')
					.attr('transform', `translate(${margin.left}, 0)`)
					.selectAll('rect')
					.data(barData)
					.join('rect')
					.attr('x', (d) => xScaleBar(d.x))
					.attr('width', xScaleBar.bandwidth())
					.attr('y', height - margin.bottom)
					.attr('height', 0)
					.attr('fill', (d) => series.find((o) => o.key === d.series)?.colour || '#122444')

				bars.transition()
					.duration(500)
					.ease(d3.easeLinear)
					.attr('y', (d) => yScale(d.y))
					.attr('height', (d) => Math.max(yScale(0) - yScale(d.y), 0))

				// draw series lines
				const lines = svg
					.select('.plot-area .lines')
					.attr('transform', `translate(${margin.left}, 0)`)
					.selectAll('path')
					.data(lineData)
					.join('path')
					.attr('fill', 'none')
					.attr('stroke-width', (d) => series.find((o) => o.key === d[0])?.lineSize)
					.attr('stroke', (d) => series.find((o) => o.key === d[0])?.colour || '#122444')
					.attr('d', (d) => {
						return d3
							.line()
							.x((d) => xScale(d.x))
							.y((d) => yScale(d.y))(d[1])
					})

				// draw mini series lines
				svg.select('.plot-area.mini .lines')
					.attr('transform', `translate(${margin.left}, ${height})`)
					.selectAll('path')
					.data(lineData)
					.join('path')
					.attr('fill', 'none')
					.attr('stroke-width', (d) => series.find((o) => o.key === d[0])?.lineSize)
					.attr('stroke', (d) => series.find((o) => o.key === d[0])?.colour || '#122444')
					.attr('d', (d) => {
						return d3
							.line()
							.x((d) => xScale(d.x))
							.y((d) => yScale1(d.y))(d[1])
					})

				// Add brushing
				var brush = d3
					.brushX() // Add the brush feature using the d3.brush function
					.extent([
						[margin.left, height],
						[width, height + miniHeight],
					]) // initialise the brush area: start at 0,0 and finishes at width,height: it means I select the whole graph area
					.on('end', updateChart) // Each time the brush selection changes, trigger the 'updateChart' function

				// Create the line variable: where both the line and the brush take place
				var line = svg.select('g.clip')

				// Add the brushing
				line.select('g.brush').call(brush)
				line.select('g.brush rect.selection').attr('fill', '#d0d6e6')

				// A function that set idleTimeOut to null
				var idleTimeout
				function idled() {
					idleTimeout = null
				}

				// A function that update the chart for given boundaries
				function updateChart({ selection }) {
					// What are the selected boundaries?
					const extent = selection

					// If no selection, back to initial coordinate. Otherwise, update X axis domain
					if (!extent) {
						if (!idleTimeout) return (idleTimeout = setTimeout(idled, 350)) // This allows to wait a little bit
						xScale.domain(xExtent)
						xScaleBar.domain(data.map((d) => d.x))
					} else {
						const x0 = originalScale.invert(extent[0] - margin.left)
						const x1 = originalScale.invert(extent[1] - margin.left)

						xScale.domain([x0, x1])

						const bandX1 = d3.bisectLeft(
							data.filter((o) => o.series === baseSeries.key).map((o) => o.x),
							x0
						)
						const bandX2 = d3.bisectLeft(
							data.filter((o) => o.series === baseSeries.key).map((o) => o.x),
							x1
						)
						xScaleBar.domain(data.map((d) => d.x).slice(bandX1, bandX2))
					}

					// Update axis and line position
					const newXAxis = d3.axisBottom(xScale).tickSize(8).tickPadding(8)

					xAxis.transition().duration(1000).call(newXAxis)

					svg.select('.x-axis .domain').remove()
					svg.selectAll('.x-axis .tick line').attr('stroke', '#D9D9D9')
					svg.selectAll('.x-axis .tick text').attr('fill', '#898888').attr('font-size', 12)

					// update bars
					bars.transition()
						.duration(1000)
						.attr('x', (d) => xScaleBar(d.x) || -xScaleBar.bandwidth())
						.attr('width', xScaleBar.bandwidth())

					// update lines
					lines
						.transition()
						.duration(1000)
						.attr('d', (d) => {
							return d3
								.line()
								.x((d) => xScale(d.x))
								.y((d) => yScale(d.y))(d[1])
						})
				}

				// animate series
				const nodes = lines.nodes()
				for (let index = 0; index < nodes.length; index++) {
					const element = nodes[index]

					const totalLength = element.getTotalLength()
					d3.select(element)
						.attr('stroke-dasharray', totalLength + ' ' + totalLength)
						.attr('stroke-dashoffset', totalLength)
						.transition()
						.duration(1500)
						.ease(d3.easeLinear)
						.attr('stroke-dashoffset', 0)
				}
			}
		},
		[series, axisLabel]
	)

	return (
		<div>
			<div ref={graphContainerRef} className='barline-graph-container series'>
				<svg ref={ref} className='barline-graph' viewBox={`0 0 ${width} ${height + miniHeight}`} preserveAspectRatio='xMinYMin meet'>
					{/* <g>
						<rect width={width - margin.left - margin.right} height={height - margin.bottom - margin.top} x={margin.left} fillOpacity={0} y={margin.top} stroke={'white'} opacity={0.5} />
					</g> */}
					<defs>
						<clipPath id='clip'>
							<rect width={width - margin.left - margin.right} height={miniHeight} x={margin.left} y={height} />
						</clipPath>
						<clipPath id='clip-main'>
							<rect width={width - margin.left - margin.right} height={height} x={margin.left} y={margin.top} />
						</clipPath>
					</defs>

					<g className='x-axis' />
					<g className='y-axis-label'></g>
					<g className='y-axis' />
					<g className='plot-area' clipPath='url(#clip-main)'>
						<g className='bars'></g>
						<g className='lines'></g>
					</g>

					<g className='x-axis-seek'></g>
					<g className='plot-area mini'>
						<g className='lines'></g>
					</g>

					<g clipPath='url(#clip)' className='clip'>
						<g className='brush'></g>
					</g>
				</svg>
			</div>
		</div>
	)
}

export default BoxsetRowContentGraphTimeSeries
