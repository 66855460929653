import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import * as d3 from 'd3'
import { useD3 } from '../../hooks/useD3'
import orderBy from 'lodash.orderby'

const height = 300
const width = 500

const margin = { top: 0, right: 0, bottom: 0, left: 0 }

export const ETP = ({ product }) => {
	const { name, isin, description, full_slug, card_series_suffix } = product
	const [series, setSeries] = useState()

	const ref = useD3(
		(svg) => {
			if (series && svg) {
				const isYNumber = series.scaleY === 'CURRENCY' || 'PERCENT'
				const data = orderBy(
					series.dataX.map((x, i) => {
						return { x: d3.timeParse('%Y/%m/%d')(x), y: isYNumber ? +series.dataY[i] : series.dataY[i] }
					}),
					['x'],
					['asc']
				)

				const yMinValue = d3.min(data, (d) => d.y)
				const yMaxValue = d3.max(data, (d) => d.y)

				svg.selectAll('*').remove()
				svg.append('svg')
					.attr('width', width + margin.left + margin.right)
					.attr('height', height + margin.top + margin.bottom)
					.append('g')
					.attr('transform', `translate(${margin.left},${margin.top})`)

				var xExtent = d3.extent(data, (d) => d.x)

				const xScale = d3.scaleTime().range([0, width]).domain(xExtent) //d3.scaleLinear().domain([xMinValue, xMaxValue]).range([0, width])
				const yScale = d3.scaleLinear().range([height, 0]).domain([yMinValue, yMaxValue]).nice()
				const line = d3
					.line()
					.x((d) => xScale(d.x))
					.y((d) => yScale(d.y))
					.curve(d3.curveMonotoneX)

				svg.append('path').datum(data).attr('fill', 'none').attr('stroke-width', series.lineSize).attr('class', 'product-line').attr('d', line)
			}
		},
		[series]
	)
	useEffect(() => {
		fetch(`${process.env.REACT_APP_API_URI}/Widgets?ApiKey=${process.env.REACT_APP_API_KEY}&names=ISIN_GRAPH_${isin.toUpperCase()}${card_series_suffix !== null && card_series_suffix ? card_series_suffix : ''}`, {
			method: 'GET',
			headers: {
				'Content-Type': 'application/json',
			},
		}).then((value) => {
			value.json().then((json) => {
				const widget = json.find((val) => val.key === `GRAPH_WIDGET_${isin.toUpperCase()}`)
				const section = widget?.sections.find((section) => section.key === `GRAPH_SECTION_${isin.toUpperCase()}`)
				const { graph } = section

				const series = graph.series.find((series) => series.key === isin.toUpperCase())

				setSeries(series)
			})
		})
	}, [card_series_suffix, isin])

	return (
		<Link to={full_slug} className={'featured-product'}>
			<div className='product-graph'>
				<svg ref={ref} className='line-graph' viewBox={`0 0 ${width} ${height}`} preserveAspectRatio='xMinYMin meet'>
					<g className='plot-area' />
				</svg>
			</div>

			<h5>{name}</h5>

			{description && <p>{description}</p>}

			<div className='more-info' />
		</Link>
	)
}

export default ETP
