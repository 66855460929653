import React, { useMemo, useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import languages from '../../constants/languages.json'
import * as d3 from 'd3'

import { storyblokEditable } from '@storyblok/react'

const blacklist = [
    'news',
    'events',
    'regulatory-news',
    'articles',
    'research',
    'reports',
    'knowledge-bytes',
    'boxsets'
]

const AssetSummary = ({ blok }) => {
	const location = useLocation()

	const slug = useMemo(() => {
		let paths = location.pathname.substring(1).split('/')

		var hasLanguage = false

		for (let index = 0; index < languages.length; index++) {
			var element = languages[index]
			hasLanguage = element.hasOwnProperty(paths[0])

			if (hasLanguage) break
		}

		if (hasLanguage) {
			paths = paths.slice(1)
		}

		return paths.join('/') || 'home'
	}, [location.pathname])

	const { header_1, header_2, header_3, cache_key_1, cache_key_2, cache_key_3 } = blok

	const [values, setValues] = useState([
		{ value: '', suffix: '' },
		{ value: '', suffix: '' },
		{ value: '', suffix: '' },
	])

	useEffect(() => {
		fetch(`${process.env.REACT_APP_API_URI}/Widgets?ApiKey=${process.env.REACT_APP_API_KEY}&names=ASSET_SUMMARY`, {
			method: 'GET',
			headers: {
				'Content-Type': 'application/json',
			},
		}).then((value) => {
			value.json().then((value) => {
				const widget = value.find((val) => val.key === 'ASSET_SUMMARY')
				const cacheSection1 = widget.sections.find((section) => section.key === cache_key_1)
				const cacheSection2 = widget.sections.find((section) => section.key === cache_key_2)
				const cacheSection3 = widget.sections.find((section) => section.key === cache_key_3)

				const value1 = cacheSection1?.meta.find((o) => o.key === 'MarketCap' && o.suffix)
				const value2 = cacheSection2?.meta.find((o) => o.key === 'LastTradedPrice' && o.suffix)
				const value3 = cacheSection3?.meta.find((o) => o.key === 'Value' && o.suffix)
				const values = [
					{ value: value1?.value, suffix: value1?.suffix, indicator: cacheSection1?.meta.find((o) => o.key === 'Indicator')?.value, updated: cacheSection1?.updated ? d3.timeFormat('%b %d, %H:%M GMT')(new Date(cacheSection1?.updated)) : '' },
					{ value: value2?.value, suffix: value2?.suffix, indicator: cacheSection2?.meta.find((o) => o.key === 'Indicator')?.value, updated: cacheSection2?.updated ? d3.timeFormat('%b %d, %H:%M GMT')(new Date(cacheSection2?.updated)) : ''  },
					{ value: value3?.value, suffix: value3?.suffix, indicator: cacheSection3?.meta.find((o) => o.key === 'Indicator')?.value, updated: cacheSection3?.updated ? d3.timeFormat('%b %d, %H:%M GMT')(new Date(cacheSection3?.updated)) : ''  },
				]

                setValues(values)
			})
		})
	}, [cache_key_1, cache_key_2, cache_key_3])

	return !blacklist.some(element => slug.includes(element)) ? (
		<div className='container'>
			<div className='row'>
				<div {...storyblokEditable(blok)} className='asset-summary'>
					<div className='asset'>
						<div className='asset-title'>{header_1}</div>
						<div className='asset-amount'>
							<span className='text-bold'>{values[0].value}</span>
							<span className='asset-currency'>{values[0].suffix}</span>
                            {values[0].indicator && <img className={'asset-indicator ' + (values[0].indicator === '+' ? 'positive' : 'negative')} src="/images/Indicator.svg" alt={values[0].indicator}/>}
						</div>
                        <div className="asset-source">
                            {values[0].updated}
                        </div>
					</div>

					<div className='asset'>
						<div className='asset-title'>{header_2}</div>
						<div className='asset-amount'>
							<span className='text-bold'>{values[1].value}</span>
							<span className='asset-currency'>{values[1].suffix}</span>
                            {values[1].indicator && <img className={'asset-indicator ' + (values[1].indicator === '+' ? 'positive' : 'negative')} src="/images/Indicator.svg" alt={values[1].indicator}/>}
						</div>
                        <div className="asset-source">
                            {values[1].updated}
                        </div>
					</div>

					<div className='asset'>
						<div className='asset-title'>{header_3}</div>
						<div className='asset-amount'>
							<span className='text-bold'>{values[2].value}</span>
							<span className='asset-currency'>{values[2].suffix}</span>
                            {values[2].indicator && <img className={'asset-indicator ' + (values[2].indicator === '+' ? 'positive' : 'negative')} src="/images/Indicator.svg" alt={values[2].indicator}/>}
						</div>
                        <div className="asset-source">
                            {values[2].updated}
                        </div>
					</div>
				</div>
			</div>
		</div>
	) : (
		<></>
	)
}

export default AssetSummary
