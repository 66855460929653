import React from 'react'
import { storyblokEditable, StoryblokComponent } from '@storyblok/react'
import { useInView } from 'react-intersection-observer'

export const Quote = ({ blok }) => {
	const { anchor_key, background = 'transparent', layout, call_to_action, extra_content, bottom_links } = blok

    const { ref, inView } = useInView({
        threshold: 0.5,
        triggerOnce: true
    })

	return (
		<div {...storyblokEditable(blok)} id={anchor_key} className={`quote-container ${background.toLowerCase()}`}>
			<div className='container'>
				<div className={`row${inView ? ' animate-ease-up' : ''}`} ref={ref}>
					{layout === 'layout_1' && (
						<>
							<div className='col-lg-6'></div>
							<div className='col-lg-6'>
								<div className='quote'>
									<p>{blok.text}</p>

									{extra_content && extra_content.length > 0 && (
										<div className='quote-extra'>
											{extra_content.map((blok) => (
												<StoryblokComponent blok={blok} key={blok._uid} />
											))}
										</div>
									)}

									{bottom_links && bottom_links.length > 0 && (
										<div className='links'>
											{bottom_links.map((blok) => (
												<StoryblokComponent blok={blok} key={blok._uid} />
											))}
										</div>
									)}
								</div>
								{call_to_action.length > 0 && (
									<div className='quote-actions'>
										{call_to_action.map((blok) => (
											<StoryblokComponent blok={blok} key={blok._uid} />
										))}
									</div>
								)}
							</div>
						</>
					)}

					{layout === 'layout_2' && (
						<>
							<div className='col-lg-2'></div>
							<div className='col-lg-6'>
								<p className='quote'>{blok.text}</p>
								{call_to_action.length > 0 && (
									<div className='quote-actions'>
										{call_to_action.map((blok) => (
											<StoryblokComponent blok={blok} key={blok._uid} />
										))}
									</div>
								)}
							</div>
							<div className='col-lg-4'></div>
						</>
					)}

					{layout === 'layout_3' && (
						<>
							<div className='col-lg-5'>
								<p className='quote'>{blok.text}</p>
								{call_to_action.length > 0 && (
									<div className='quote-actions'>
										{call_to_action.map((blok) => (
											<StoryblokComponent blok={blok} key={blok._uid} />
										))}
									</div>
								)}
							</div>
							<div className='col-lg-2'></div>
							<div className='col-lg-5 extra-content'>{extra_content && extra_content.map((blok) => <StoryblokComponent blok={blok} key={blok._uid} />)}</div>
						</>
					)}

					{layout === 'layout_4' && (
						<>
							<div className='col-lg-3'></div>
							<div className='col-lg-6'>
								<p className='quote' style={{ margin: 0 }}>
									{blok.text}
								</p>
								{bottom_links && bottom_links.length > 0 && (
									<div className='links'>
										{bottom_links.map((blok) => (
											<StoryblokComponent blok={blok} key={blok._uid} />
										))}
									</div>
								)}
							</div>
							<div className='col-lg-3' style={{ textAlign: 'right' }}>
								{call_to_action.length > 0 && (
									<div>
										{call_to_action.map((blok) => (
											<StoryblokComponent blok={blok} key={blok._uid} />
										))}
									</div>
								)}
							</div>
						</>
					)}
				</div>
			</div>
		</div>
	)
}

export default Quote
