import React from 'react'

import { storyblokEditable, StoryblokComponent } from '@storyblok/react'

const Footer = ({ blok, className }) => {
	const { left_navigation /*, right_navigation */, logo, social_media, copyright } = blok

	return (
		<div {...storyblokEditable(blok)} className={`container ${className || ''}`}>
			<div className='row'>
				<div className='footer'>
					{/* <div className='d-flex justify-space-between'> */}
					{/* <div className='left-navigation d-flex'>
							{left_navigation.map((blok, index) => (
								<StoryblokComponent blok={blok} key={blok._uid} copyright={copyright}/>
							))}
						</div> */}
					<div className='footer-navigation  d-flex'>
						{left_navigation.map((blok, index) => (
							<StoryblokComponent blok={blok} key={blok._uid} copyright={copyright} />
						))}
						{/* {right_navigation.map((blok) => (
								<StoryblokComponent blok={blok} key={blok._uid} />
							))} */}
					</div>
					{/* </div> */}

					<div className='logo'>
						{logo.map((blok) => (
							<StoryblokComponent blok={blok} key={blok._uid} />
						))}

                        {copyright && <span className='copyright'>&copy; {copyright}</span>}
					</div>

					<div className='social-media'>
						{social_media.map((blok) => (
							<StoryblokComponent blok={blok} key={blok._uid} />
						))}
					</div>
				</div>
			</div>
		</div>
	)
}

export default Footer
