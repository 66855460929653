import React, { useMemo } from 'react'
import './Button.scss'

import { storyblokEditable } from '@storyblok/react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Link } from 'react-router-dom'
import { useCallback } from 'react'

export const Button = ({ blok }) => {
	const { link, style, type, icon, copy_text, box_document } = blok
	const { anchor, cached_url, linktype } = link

	const className = useMemo(() => {
		const classNames = ['button']

		switch (style) {
			case '2':
				classNames.push('secondary')
				break
			case '3':
				classNames.push('gray')
				break
			default:
				break
		}

		switch (type) {
			case 'link_button':
				classNames.push('link-button')
				break

			default:
				break
		}

		return classNames.join(' ')
	}, [type, style])

	const handleCopy = useCallback(() => {
		if (copy_text) {
			navigator.clipboard.writeText(copy_text)
		}
	}, [copy_text])

	if (box_document && box_document.assets.length > 0) {
		return (
			<a href={`/box/download/${box_document.assets[0].key}`} className={className} target='_blank' rel='noreferrer'>
				<span>{blok.text}</span>
				{linktype === 'asset' && (
					<>
						<img src='/images/DownloadIcon.svg' alt='download' className='button-icon-dark' />
						<img src='/images/DownloadIconLight.svg' className='button-icon-light' alt='download' />
					</>
				)}
				{linktype !== 'asset' && icon && icon.icon && <FontAwesomeIcon icon={`${icon.type} ${icon.icon}`} />}
			</a>
		)
	} else if (type === 'copy_button') {
		return (
			<button className={className} onClick={handleCopy}>
				<span>{blok.text}</span>
				{icon && icon.icon && <FontAwesomeIcon icon={`${icon.type} ${icon.icon}`} />}
			</button>
		)
	} else if (linktype === 'story' && !anchor) {
		return (
			<Link {...storyblokEditable(blok)} to={cached_url || '/'} className={className}>
				<span>{blok.text}</span>
				{icon && icon.icon && <FontAwesomeIcon icon={`${icon.type} ${icon.icon}`} />}
			</Link>
		)
	} else if (linktype === 'story' && anchor) {
		return (
			<a {...storyblokEditable(blok)} href={`#${anchor}`} className={className}>
				<span>{blok.text}</span>
				<img src='/images/ChevronDown.svg' alt='download' className='button-icon-dark' />
				<img src='/images/ChevronDownLight.svg' alt='download' className='button-icon-light' />
			</a>
		)
	} else if (linktype === 'url') {
		return (
			<a {...storyblokEditable(blok)} href={cached_url} target='_blank' rel='noreferrer' className={className}>
				<span>{blok.text}</span>
				{icon && icon.icon && <FontAwesomeIcon fontSize={20} icon={`${icon.type} ${icon.icon}`} />}
			</a>
		)
	} else if (linktype === 'asset') {
		return (
			<a {...storyblokEditable(blok)} href={cached_url} target='_blank' rel='noreferrer' className={className}>
				<span>{blok.text}</span>
				<img src='/images/DownloadIcon.svg' alt='download' className='button-icon-dark' />
				<img src='/images/DownloadIconLight.svg' className='button-icon-light' alt='download' />
			</a>
		)
	}
}

export default Button
