import { ChoiceGroup, Slider } from '@fluentui/react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { StoryblokComponent } from '@storyblok/react'
import React, { useState, useEffect } from 'react'
import { getCookie } from '../PopupNotice'

export const Owl = ({ blok }) => {
	const { display_interval, feedback_links, feedback_text, key, maximum_value, minimum_value, options, question, question_type, snap_interval, subscribe_form, subscribe_text, suffix, title } = blok

	const [displayOwl, setDisplayOwl] = useState(false)
	const [selectedOption, setSelectedOption] = useState(undefined)
	const [step, setStep] = useState(1)

	useEffect(() => {
		const getOwlOccurence = async () => {
			const res = await fetch(`${process.env.REACT_APP_API_URI}/OwlsViewStats?ApiKey=${process.env.REACT_APP_API_KEY}&key=${key}`, {
				method: 'GET',
				headers: {
					'Content-Type': 'application/json',
				},
			})

			const data = await res.json()

			if (data % (+display_interval) === 0) {
				setDisplayOwl(true)
			} else setDisplayOwl(false)
		}

		const increaseOwlOccurence = async () => {
			await fetch(`${process.env.REACT_APP_API_URI}/CustomActions?ApiKey=${process.env.REACT_APP_API_KEY}`, {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
				},
				body: JSON.stringify([
					{
						key: key,
						name: 'VIEWED-OWL',
						data: JSON.stringify({
							source: 'website',
							code: key,
						}),
					},
				]),
			})

			getOwlOccurence()
		}

		increaseOwlOccurence()
	}, [key, display_interval])

	const handleOptionChange = (ev, option) => {
		setSelectedOption(option.key)
	}

	const handleQuestionSubmit = () => {
		if (!selectedOption) return

        const logOwlResponse = async () => {
            await fetch(`${process.env.REACT_APP_API_URI}/CustomActions?ApiKey=${process.env.REACT_APP_API_KEY}}`, {
                method: 'POST',
				headers: {
					'Content-Type': 'application/json',
				},
				body: JSON.stringify([
					{
						key: getCookie('visitor_id882933'),
						name: 'ANSWERED-OWL',
						data: JSON.stringify({
							source: 'website',
							code: key,
                            answer: selectedOption
						}),
					},
				]),
            })
        }

        logOwlResponse()

		setStep((step) => step + 1)
	}

    const handleShowContactForm = () => {
        setStep(step => step + 1)
    }

	return (
		<>
			{displayOwl === true && (
				<div className={`owl-container  ${step > 1 ? 'alternate' : ''}`}>
					<div className='owl'>
						<h5>{title}</h5>
						{step === 1 && (
							<div className='owl-description'>
								<p>{question}</p>
								<div className='owl-question'>
									<>
										{question_type === 'yes_no' && (
											<>
												<ChoiceGroup
													className='owl-choices'
													styles={{
														flexContainer: {
															display: 'flex',
															columnGap: 60,
															flexWrap: 'wrap',
														},
													}}
													onChange={handleOptionChange}
													selectedKey={selectedOption}
													options={[
														{ key: 'yes', text: 'Yes' },
														{ key: 'no', text: 'No' },
														{ key: 'maybe', text: 'Dont Know' },
													]}
												/>
											</>
										)}

										{question_type === 'single_option' && (
											<ChoiceGroup
												className='owl-choices'
												styles={{
													flexContainer: {
														display: 'flex',
														columnGap: 60,
														flexWrap: 'wrap',
													},
												}}
												onChange={handleOptionChange}
												selectedKey={selectedOption}
												options={options.split('\n').map((o) => {
													return { key: o, text: o }
												})}
											/>
										)}

										{question_type === 'single_value' && (
                                            <div className="slider">
											<Slider
												styles={{
													root: {
														width: '100%',
													},
													activeSection: {
														backgroundColor: '#122444',
													},
													inactiveSection: {
														backgroundColor: 'white',
														height: 1,
														marginTop: 1.5,
													},
													thumb: {
														borderColor: '#122444',
														borderWidth: 2,
													},
													valueLabel: {
														color: '#122444',
													},
													slideBox: {
														paddingLeft: 0,
													},
												}}
												aria-label='Slider'
												min={+minimum_value}
												max={+maximum_value}
												step={+snap_interval}
												defaultValue={0}
												showValue={false}
												snapToStep
												onChange={(value) => setSelectedOption(value)}
											/>
                                            <span className='body-intro' style={{width: 80, paddingLeft: 20, fontWeight: 'bold'}}>
                                                {selectedOption || +minimum_value} {suffix}
                                            </span>
                                            </div>
										)}

										<button className='button' onClick={handleQuestionSubmit}>
											Submit
										</button>
									</>
								</div>
							</div>
						)}

						{step === 2 && (
							<div className='owl-description'>
								<p>{feedback_text}</p>
								<div className='owl-question buttons'>
									{feedback_links.map((o) => (
										<React.Fragment key={o._uid}>
											{o.buttons.map((blok) => (
												<StoryblokComponent blok={blok} key={blok._uid} />
											))}
										</React.Fragment>
									))}

									{subscribe_text && <button className='button link-button' onClick={handleShowContactForm}>
										<span>{subscribe_text}</span>
										<FontAwesomeIcon icon='fas fa-angle-right' />
									</button>}
								</div>
							</div>
						)}

						{step === 3 && subscribe_form.map((blok) => <StoryblokComponent blok={blok} key={blok._uid} />)}
					</div>
				</div>
			)}
		</>
	)
}

export default Owl
