import React, { useState } from 'react'
import { storyblokEditable } from '@storyblok/react'
import TwitterShare from '../Share/TwitterShare'
import LinkedinShare from '../Share/LinkedInShare'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Callout } from '@fluentui/react'
import { useLocation } from 'react-router-dom'

export const BoxsetTableOfContents = ({ blok }) => {
	const { background = 'transparent', anchor_key, title, description, sections = [], background_image } = blok
	const [showShare, setShowShare] = useState(false)
	const location = useLocation()

	return (
		<div className={background.toLowerCase()} id={anchor_key}>
			<div {...storyblokEditable(blok)} className='widget'>
				<div className='container'>
					<div className='row boxset-header'>
						{background_image && background_image.filename && (
							<div className='boxset-image'>
								<img src={background_image.filename} alt={background_image.alt} />
							</div>
						)}
						<div className='boxset-share'>
							<button className={`share-button ${showShare ? 'close' : ''}`} id={'share-button'} onClick={() => setShowShare((show) => !show)}>
								<img src='/images/ShareLight.svg' alt='share' className='share' />
								<div className='close-icon'></div>
							</button>
							{showShare && (
								<Callout className={`share-dropdown-container`} target='#share-button' gapSpace={12} isBeakVisible={false} styles={{ root: { backgroundColor: 'transparent !important'}}} onDismiss={() => setShowShare(false)}>
									<div className='share-dropdown'>
										<TwitterShare url={window.location.origin + location.pathname} title='Share by Twitter' />
										<LinkedinShare url={window.location.origin + location.pathname} title='Share by Linkedin' />
										<a href={`mailto:?body=${encodeURI(window.location.origin + location.pathname)}%0D%0A%0D%0A&subject=${encodeURIComponent(document.title)}`} title='Share by Email'>
											<FontAwesomeIcon icon='fas fa-envelope' />
										</a>
									</div>
								</Callout>
							)}
						</div>
						<div className='col-lg-6 col-12'>
							<div className='boxset-table-of-contents'>
								<h1>{title}</h1>
								<p className='body-intro'>{description}</p>

								<div className='boxset-navigation'>
									{sections.map(({ _uid, index, title, link }) => (
										<a className='boxset-navigation-item' key={_uid} href={`#${link?.anchor}`}>
											<span className='boxset-nav-index'>{index.padStart(2, '0')}</span>
											<span className='boxset-nav-title'>{title}</span>
										</a>
									))}
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}

export default BoxsetTableOfContents
