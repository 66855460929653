import React, { useState } from 'react'
import { RichText } from '../RichText'

export const BoxsetEvidence = ({ blok }) => {
	const { title, description, images = [] } = blok
    const [selectedImage, setSelectedImage] = useState(images.length > 0 ? images[0] : undefined)
    
	return (
		<div className='boxset-evidence'>
			{(title || description) && (
				<div className='boxset-evidence-info'>
					{title && <h5>{title}</h5>}
					{description && <RichText inline={true} blok={description} />}
				</div>
			)}

			<div className='boxset-evidence-images'>
				{/* <div className='image-display'>
					<div className='image-row'>{images && images.map((img) => <img key={img.id} src={img.filename} alt={img.alt} />)}</div>
				</div> */}
                {selectedImage && <img src={selectedImage.filename} alt={selectedImage.alt} />}
				<div className='image-selector'>
                    {images.map(img => <button className={img === selectedImage ? 'selected' : ''} key={img.id} onClick={() => setSelectedImage(img)}></button>)}
                </div>
			</div>
		</div>
	)
}

export default BoxsetEvidence
