import React from 'react'

import { storyblokEditable, StoryblokComponent } from '@storyblok/react'

export const Headline = ({ blok }) => {
	const { anchor_key, breadcrumb, layout, call_to_action, title, header } = blok

	return (
		<div {...storyblokEditable(blok)} className="headline-container" id={anchor_key}>
			<div className='container'>
				<div className='row'>
					<div className='col-lg-12 col-12'>
						<div className='headline'>
							{breadcrumb && breadcrumb.map((blok) => <StoryblokComponent blok={blok} key={blok._uid} />)}
							{(!layout || layout === 'layout_1') && (
								<>
									<div className='row'>
										<div className='col-lg-7 col-12'>
											<h1>{title}</h1>
											{header && <span className='text-lightest sub-header'>{header}</span>}

											{call_to_action && call_to_action.length > 0 && (
												<span style={{ display: 'inline-flex', gap: 30, marginTop: 30 }}>
													{call_to_action.map((blok) => (
														<StoryblokComponent blok={blok} key={blok._uid} />
													))}
												</span>
											)}
										</div>
									</div>
								</>
							)}

							{layout === 'layout_2' && (
								<>
									<div className='row'>
										<div className='col-lg-7 col-12'>
											<h1 style={{ display: 'flex', flexDirection: 'column' }}>
												{title}
												<span className='text-lightest'>{header}</span>
											</h1>
										</div>
										<div className='col-lg-5 col-12 headline-actions'>
											{call_to_action.map((blok) => (
												<StoryblokComponent blok={blok} key={blok._uid} />
											))}
										</div>
									</div>
								</>
							)}
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}

export default Headline
