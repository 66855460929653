import React from 'react'

import { storyblokEditable } from '@storyblok/react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Link } from 'react-router-dom'

const ImageLink = ({ blok }) => {
	const { link, icon, image } = blok

	return (
		<div {...storyblokEditable(blok)} className='image-link'>
			{link.linktype === 'story' && (
				<Link to={link.cached_url || '/'}>
					{icon.icon && icon.icon !== '' ? (
						<>
							<FontAwesomeIcon fontSize={20} icon={`${icon.type} ${icon.icon}`} />
						</>
					) : (
						<img src={image.filename} alt={image.alt} />
					)}
				</Link>
			)}

			{link.url && (
				<a href={link.url} target='_blank' rel="noreferrer">
					{icon.icon !== '' && icon.icon ? (
						<>
							<FontAwesomeIcon fontSize={20} icon={`${icon.type} ${icon.icon}`} />
						</>
					) : (
						<img src={image.filename} alt={image.alt} />
					)}
				</a>
			)}
		</div>
	)
}

export default ImageLink
