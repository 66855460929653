import React from 'react'
import { Link, NavLink } from 'react-router-dom'

import { storyblokEditable, StoryblokComponent } from '@storyblok/react'

const NavigationItem = ({ blok, nav, onSelectSubNavigation, selectedNavigation }) => {
	const { link, navigation, image } = blok
	const { url, cached_url, linktype } = link

	const target_url = cached_url || url

	if (selectedNavigation && selectedNavigation !== target_url) return <></>
	if (selectedNavigation === target_url) {
		return (
			<>
				<div className='navigation-item navigate sub' onClick={() => onSelectSubNavigation()}>
					<img className='navigate-forward reverse' src='/images/ChevronRightSmall.svg' alt='forward' />
					<span>Back</span>
				</div>
				{navigation && navigation.map((o) => <StoryblokComponent key={o._uid} blok={o} className={selectedNavigation === target_url ? 'open' : ''} onSelectSubNavigation={() => onSelectSubNavigation('')} />)}
			</>
		)
	}

	if (linktype === 'story' && nav) {
		return (
			<>
				<NavLink
					className={({ isActive }) => {
						const linkClasses = ['navigation-item']
						if (isActive) linkClasses.push('active')
						if (navigation) linkClasses.push('navigate')
						return linkClasses.join(' ')
					}}
					to={target_url.replace(/\/$/, '')}
				>
					<span>{blok.name}</span>
					{navigation && (
						<span
							style={{ flex: 1, height: '100%', display: 'flex', justifyContent: 'flex-end', alignItems: 'center', zIndex: 2 }}
							onClick={(ev) => {
                                ev.preventDefault()
								ev.stopPropagation()
								onSelectSubNavigation(target_url)
								return false
							}}
						>
							<img className='navigate-forward' src='/images/ChevronRightSmall.svg' alt='forward' />
						</span>
					)}
				</NavLink>
			</>
		)
	} else if (linktype === 'story' && !nav) {
		return (
			<Link {...storyblokEditable(blok)} className='navigation-item' to={target_url}>
				{image && image.filename && <img src={image.filename} alt={image.alt} />}
				<span>{blok.name}</span>
			</Link>
		)
	} else
		return (
			<a {...storyblokEditable(blok)} className='navigation-item' href={target_url} target='_blank' rel='noreferrer'>
                {image && image.filename && <img src={image.filename} alt={image.alt} />}
				{blok.name}
			</a>
		)
}

export default NavigationItem
