import React, { useMemo } from 'react'
import ETPDocumentsBox from './ETPDocumentsBox'
import ETPDocumentsStoryblok from './ETPDocumentsStoryblok'

export const ETPDocuments = ({ blok }) => {
	const { box_folder_path } = blok

	const isBox = useMemo(() => box_folder_path && box_folder_path.path, [box_folder_path])

	return (
		<>
			{!isBox && <ETPDocumentsStoryblok blok={blok} />}
			{isBox && <ETPDocumentsBox blok={blok} />}
		</>
	)
}

export default ETPDocuments
