import React, { useEffect, useState, useMemo, useRef } from 'react'
import { storyblokEditable } from '@storyblok/react'
import { useD3 } from '../../hooks/useD3'
import orderBy from 'lodash.orderby'
import * as d3 from 'd3'
import { useCallback } from 'react'
import { useQuery, gql } from '@apollo/client'

const height = 320
const width = 500

const margin = { top: 5, right: 0, bottom: 35, left: 75 }

export const MarketPerformanceGraph = ({ blok, isin, bloomberg, name, language }) => {
	const { anchor_key, background = 'transparent', coin_entitlement_calculator, disclaimer, series_suffix = '' } = blok

	const [series, setSeries] = useState()
	const [overview, setOverview] = useState()
	const [selectedSeries, setSelectedSeries] = useState([...(blok.series?.map((o) => o.identifier?.toUpperCase()) || ''), isin || bloomberg])

	const query = gql`
    {
        DatasourceEntries(datasource: "key-translations", dimension: "${language}", per_page: 1000) {
            items {
                name
                dimensionValue
                value
            }
        }
    }
`
	const { /*loading, error,*/ data } = useQuery(query)
	const { DatasourceEntries } = data || {}

	const datasourceEntries = useMemo(() => {
		if (DatasourceEntries) return DatasourceEntries.items

		return []
	}, [DatasourceEntries])

	const toggleSeries = useCallback((selectSeries) => {
		setSelectedSeries((selectedSeries) => {
			const newSelection = [...selectedSeries]

			const index = newSelection.indexOf(selectSeries)
			if (index >= 0) {
				newSelection.splice(index, 1)
			} else newSelection.push(selectSeries)

			return newSelection
		})
	}, [])

	const graphContainerRef = useRef()
	const ref = useD3(
		(svg) => {
			if (graphContainerRef) {
				// hide tooltip and line
				svg.selectAll('.tooltip-line').attr('opacity', 0)
				d3.select(graphContainerRef.current).selectAll('.tooltip-container').attr('opacity', 0)
			}

			if (series && graphContainerRef) {
				const baseSeries = series.find((series) => series.key === isin || bloomberg)
				const isYNumber = baseSeries?.scaleY === 'CURRENCY' || 'PERCENT'
				const prefix = baseSeries?.prefixY || ''
				const suffix = baseSeries?.suffixY || ''

				let data = orderBy(
					series.flatMap((o) =>
						o.dataX.flatMap((c, i) => {
							return {
								x: d3.timeParse('%Y/%m/%d')(c) || new Date(c),
								y: isYNumber ? +o.dataY[i] : o.dataY[i],
								series: o.key,
							}
						})
					),
					['series', 'x'],
					['asc', 'asc']
				)

				const minDate = data.find((o) => o.series === (isin || bloomberg)).x
				data = data.filter((o) => o.x >= minDate)

				var xExtent = d3.extent(
					data.filter((o) => o.series === baseSeries.key),
					(d) => d.x
				)

				const xScale = d3
					.scaleTime()
					.domain(xExtent)
					.range([0, width - margin.left])

				const yMaxValue = d3.max(data, (d) => d.y)
				const yMinValue = d3.min(data, (d) => d.y)

				const yScale = d3
					.scaleLinear()
					.range([height - margin.bottom, 0])
					.domain([yMinValue, yMaxValue])
					.nice(5)

				const sumstat = d3.group(data, (d) => d.series)

				// x axis
				const maxDate = xExtent[1]
				const ticks = []
				const fullLength = Math.floor((maxDate.getTime() - minDate.getTime()) / (1000 * 3600 * 24))
				const nthTick = Math.ceil(fullLength / 6)

				for (let i = 0; i < fullLength; i += nthTick) {
					var result = new Date(minDate)
					result.setDate(result.getDate() + i)
					ticks.push(result)
				}

				const xAxis = d3
					.axisBottom()
					.scale(xScale)
					.tickValues(ticks)
					.tickSizeInner(0)
					.tickSizeOuter(-1 * (height - margin.top))
					.tickPadding(18)
					.tickFormat(d3.utcFormat("%b '%y"))

				svg.select('.x-axis')
					.attr('transform', `translate(${margin.left}, ${height - margin.bottom + margin.top})`)
					.call(xAxis)

				d3.select('.x-axis .tick:first-of-type').remove()

				d3.selectAll('.x-axis .tick line').attr('y2', -(height - margin.bottom))

				// y axis
				const yAxis = d3
					.axisLeft()
					.scale(yScale)
					.ticks(5)
					.tickSizeInner(0)
					.tickSizeOuter(-1 * (width - margin.left))
					.tickPadding(18)
					.tickFormat((d) => `${prefix ? prefix + ' ' : ''}${d3.format(',.0f')(d)}${suffix ? ' ' + suffix : ''}`)

				d3.select('.y-axis').attr('transform', `translate(${margin.left}, ${margin.top})`).call(yAxis)
				d3.select('.y-axis .tick:last-child line').remove()

				d3.selectAll('.y-axis .tick line').attr('x2', width)

				// draw tooltip hover
				const focus = svg.selectAll('.tooltip-line').style('opacity', 0)
				const focusText = d3.select(graphContainerRef.current).selectAll('.tooltip-container').style('opacity', 0)

				// draw series lines
				const lines = svg
					.select('.plot-area')
					.attr('transform', `translate(${margin.left}, ${margin.top})`)
					// .attr('width', width - margin.left)
					.selectAll('path')
					.data(sumstat)
					.join('path')
					.classed('disabled', (d) => selectedSeries.findIndex((o) => o.toUpperCase() === d[0]) < 0)
					.attr('fill', 'none')
					.attr('stroke-width', (d) => series.find((o) => o.key === d[0])?.lineSize)
					.attr('stroke', (d) => series.find((o) => o.key === d[0])?.colour || 'white')
					.attr('d', (d) => {
						return d3
							.line()
							.x((d) => xScale(d.x))
							.y((d) => yScale(d.y))(d[1])
					})

				// animate series
				const nodes = lines.nodes()
				for (let index = 0; index < nodes.length; index++) {
					const element = nodes[index]

					const totalLength = element.getTotalLength()
					d3.select(element)
						.attr('stroke-dasharray', totalLength + ' ' + totalLength)
						.attr('stroke-dashoffset', totalLength)
						.transition()
						.duration(1500)
						.ease(d3.easeLinear)
						.attr('stroke-dashoffset', 0)
				}

				// mouse tooltip positioning
				svg.selectAll('.hover-container').remove()

				svg.append('rect')
					.classed('hover-container', true)
					.style('fill', 'none')
					.attr('transform', `translate(${margin.left}, 0)`)
					.style('pointer-events', 'all')
					.attr('width', width - margin.left)
					.attr('height', height)
					.on('mousemove', mousemove)
					.on('mouseout', mouseout)

				function mousemove(ev) {
					// recover coordinate we need
					const pointerPosition = d3.pointer(ev)
					var x0 = xScale.invert(pointerPosition[0])
                    const dataPointIndex = d3.bisectLeft(
                        data.filter((o) => o.series === baseSeries.key).map((o) => o.x),
                        x0
                    )

					var selectedData =
						data[dataPointIndex]

					const tooltipX = xScale(selectedData.x) + margin.left
					const tooltipY = yScale(selectedData.y) + margin.top
					focus.attr('transform', `translate(${tooltipX}, 0)`) //.attr('cy', yScale(selectedData.y))

					const xPosition = tooltipX / (width - margin.left)
					const boundingBox = svg.node().getBoundingClientRect()
					const ratio = boundingBox.width / width
					const leftMargin = margin.left * ratio
					const focusBoundingBox = focusText.node().getBoundingClientRect()

					const tooltipPosition = {
						x: (boundingBox.width - leftMargin) * xPosition,
						y: tooltipY * ratio,
					}

					focus.style('opacity', 1)
					focusText.style('opacity', 1)
					focusText.style('left', (tooltipPosition.x > boundingBox.width - focusBoundingBox.width ? tooltipPosition.x - focusBoundingBox.width : tooltipPosition.x) + 'px').style('top', tooltipPosition.y + 'px')

                    for (let index = 0; index < series.length; index++) {
                        const serie = series[index];
                        const yValue = parseFloat(parseFloat(serie.dataY[dataPointIndex]).toFixed(2)).toLocaleString('en-US')
                        focusText.select(`.tooltip-${serie.key}`).style('background', serie.colour)
                        focusText.select(`.tooltip-${serie.key} .tooltip-x`).html(d3.utcFormat('%Y-%m-%d')(selectedData.x))
                        focusText.select(`.tooltip-${serie.key} .tooltip-y`).html(`${prefix}${yValue}${suffix}`)
                    }
					
				}
				function mouseout() {
					focus.style('opacity', 0)
					focusText.style('opacity', 0)
				}
			}
		},
		[series, selectedSeries]
	)

	useEffect(() => {
		const etpSeries = blok.series?.filter((o) => o.type === 'etp') || []
		const indexSeries = blok.series?.filter((o) => o.type === 'index') || []

		const seriesStrings = []

		if (etpSeries.length > 0) seriesStrings.push(`&etpseries=${etpSeries.map((o) => o.identifier).join(',')}`)
		if (indexSeries.length > 0) seriesStrings.push(`&indexseries=${indexSeries.map((o) => o.identifier).join(',')}`)

		fetch(`${process.env.REACT_APP_API_URI}/Widgets?ApiKey=${process.env.REACT_APP_API_KEY}&names=${isin ? 'ISIN' : 'INDEX'}_GRAPH_${isin || bloomberg}${series_suffix},${isin ? 'ISIN' : 'INDEX'}_KEYSTATS_${isin || bloomberg}${seriesStrings.join('')}`, {
			method: 'GET',
			headers: {
				'Content-Type': 'application/json',
			},
		}).then((value) => {
			value.json().then((value) => {
				const widget = value.find((val) => val.key === `GRAPH_WIDGET_${isin || bloomberg}`)
				const section = widget.sections.find((section) => section.key === `GRAPH_SECTION_${isin || bloomberg}`)
				const { graph } = section
				const { series } = graph

				setSeries(series)
				setSelectedSeries([...(blok.series?.map((o) => o.identifier?.toUpperCase()) || ''), isin || bloomberg])
				const overviewWidget = value.find((v) => v.key === `${isin ? 'ISIN' : 'INDEX'}_KEYSTATS_${isin || bloomberg}`)
				setOverview(overviewWidget.sections.find((v) => v.key === (isin ? `${isin}_OVERVIEW` : bloomberg))?.meta)
			})
		})
	}, [blok.series, bloomberg, isin, series_suffix])

	return (
		<div className={background.toLowerCase()} id={anchor_key}>
			<div {...storyblokEditable(blok)} className='container widget' style={{ paddingTop: 0 }}>
				<div className='row'>
					<div className='col'>
						<div className='graph market'>
							<div className='row'>
								<div className='col-lg-8 col-12'>
									<h5 className='graph-header'>Market Performance</h5>
									<div ref={graphContainerRef} className='line-graph-container series'>
										<svg ref={ref} className='line-graph' viewBox={`0 0 ${width} ${height}`} preserveAspectRatio='xMinYMin meet'>
											<g>
												<rect width={width - margin.left} height={height - margin.bottom} x={margin.left} fillOpacity={0} y={margin.top} stroke={'white'} opacity={0.5} />
											</g>
											<g className='x-axis'>
												<line x1={0} x2={width - margin.left} y1={0} y2={0} strokeWidth={1} stroke={'white'} />
											</g>
											<g className='y-axis' />
											<g className='plot-area' />
											<g className='tooltip-line'>
												<line x1={0} x2={0} strokeWidth={1} y1={margin.top} y2={height + margin.top - margin.bottom} stroke={'#7DC7F5'} />
											</g>
										</svg>
										<div className='tooltip-container'>
											{series && series.length > 0 && series.filter(o => selectedSeries.indexOf(o.key) >= 0).map((o) => (
												<div key={o.key} className={`tooltip tooltip-${o.key}`}>
													<div className='tooltip-x' />
													<div className='tooltip-y' />
												</div>
											))}
										</div>
									</div>
									<div className='legend'>
										{series &&
											series.length > 0 &&
											blok.series &&
											[{ identifier: isin || bloomberg, name: name }, ...blok.series].map((o) => {
												const currentSeries = series.find((s) => s.key === o.identifier.toUpperCase())
												return (
													<div key={o.identifier} className={`legend-item ${selectedSeries.indexOf(o.identifier.toUpperCase()) >= 0 ? '' : 'disabled'}`} onClick={() => toggleSeries(o.identifier.toUpperCase())}>
														<span style={{ background: currentSeries?.colour }} className='legend-indicator' />
														<span className='legend-text'>
															{o.name} <img src='/images/visibility.svg' alt='' />
														</span>
													</div>
												)
											})}
									</div>
									{disclaimer && <div className='graph-disclaimer'>{disclaimer}</div>}
								</div>
								<div className='col-lg-4 col-12'>
									<h5 className='graph-header'>Key statistics</h5>

									<div className='statistics'>
										{overview &&
											overview.map((o) => {
												const fieldName = datasourceEntries.find((de) => de.name === o.key)
												return (
													<div key={o.key} className='statistic'>
														{o.key === 'coinEntitlementAtLaunch' ? (
															<>
																<span>{fieldName?.dimensionValue || fieldName?.value || o.key}</span>
																<span className='statistic-detail'>
																	<span>{o.value} BTC at launch</span>
																	{/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
																	{coin_entitlement_calculator?.filename && (
																		<a href={coin_entitlement_calculator?.filename} target='_blank' rel='noreferrer'>
																			Download CE Calculator
																		</a>
																	)}
																</span>
															</>
														) : (
															<>
																<span>{fieldName?.dimensionValue || fieldName?.value || o.key}</span>
																<span className='statistic-detail'>
																	<span>
																		{o.prefix && <span>{o.prefix} </span>}
																		{o.value}
																		{o.suffix && <span> {o.suffix}</span>}
																	</span>
																</span>
															</>
														)}
													</div>
												)
											})}
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}

export default MarketPerformanceGraph
