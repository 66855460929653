import React, { useState, useEffect, useMemo } from 'react'
import { storyblokEditable, StoryblokComponent } from '@storyblok/react'
import { useQuery, gql } from '@apollo/client'
import ChevronLarge from '../../icons/ChevronLargeDark.svg'
import { Link } from 'react-router-dom'
import { Dropdown, DirectionalHint } from '@fluentui/react'

const infoTabs = [
	{ key: '0', text: 'Listing & Codes' },
	{ key: '1', text: 'Structure' },
	{ key: '2', text: 'Documents' },
	{ key: '3', text: 'Key Service Provider' },
	{ key: '4', text: 'Legal & Tax' },
]

export const KeyInformation = ({ blok, isin, language }) => {
	const { anchor_key, title, document_list, documents_link } = blok

	const [selectedKey, setSelectedKey] = useState('0')
	const [structure, setStructure] = useState()
	const [legalAndTax, setLegalAndTax] = useState()
	const [keyserviceProvider, setKeyserviceProvider] = useState()
	const [listingCodes, setListingCodes] = useState()

	const query = gql`
        {
            DatasourceEntries(datasource: "key-translations", dimension: "${language}", per_page: 1000) {
                items {
                    name
                    dimensionValue
                    value
                }
            }
        }
    `
	const { /*loading, error,*/ data } = useQuery(query)
	const { DatasourceEntries } = data || {}

	const datasourceEntries = useMemo(() => {
		if (DatasourceEntries) return DatasourceEntries.items

		return []
	}, [DatasourceEntries])

	useEffect(() => {
		if (isin)
			fetch(`${process.env.REACT_APP_API_URI}/Widgets?ApiKey=${process.env.REACT_APP_API_KEY}&names=ISIN_KEYSTATS_${isin},ISIN_LISTCODES_${isin}`, {
				method: 'GET',
				headers: {
					'Content-Type': 'application/json',
				},
			}).then((value) => {
				value.json().then((value) => {
					const widget = value.find((v) => v.key === `ISIN_KEYSTATS_${isin}`)
					setStructure(widget.sections.find((v) => v.key === `${isin}_STRUCTURE`)?.meta)
					setKeyserviceProvider(widget.sections.find((v) => v.key === `${isin}_KEYSERVICEPROVIDER`)?.meta)
					setLegalAndTax(widget.sections.find((v) => v.key === `${isin}_LEGALANDTAX`)?.meta)

					const listingCodes = value
						.find((v) => v.key === `ISIN_LISTCODES_${isin}`)
						.sections.flatMap((o) =>
							Object.assign(
								{},
								...o.meta.map((m) => {
									return { [m.key]: m.value }
								})
							)
						)

					setListingCodes(listingCodes)
				})
			})
	}, [isin])

	return (
		<div className='white' id={anchor_key}>
			<div {...storyblokEditable(blok)} id='key_information' className='widget'>
				<div className='container'>
					<div className='row'>
						<div className='col'>
							<div className='key-information-title'>
								<h5>{title}</h5>

								<div className='key-information-dropdown'>
									<Dropdown
                                    calloutProps={{
                                        directionalHint: DirectionalHint.bottomLeftEdge,
                                        directionalHintFixed: true
                                    }}
										className='tab-dropdown'
										options={infoTabs}
										styles={{
											dropdownItemSelected: {
												background: 'white',
												color: '#122444',
											},
										}}
										selectedKey={selectedKey}
										onChange={(ev, option) => setSelectedKey(option.key)}
									/>
								</div>
								<div className='key-information-selectors'>
									{infoTabs.map((o) => (
										<button key={o.key} className={`button ${selectedKey === o.key ? 'selected' : ''}`} onClick={() => setSelectedKey(o.key)}>
											{o.text}
										</button>
									))}
								</div>
							</div>
							<div className='key-information-content'>
								{selectedKey === '0' && listingCodes && (
									<div>
										<div className='grid-table'>
											<div className='grid-headers'>
												<div className='grid-header'>Country</div>
												<div className='grid-header'>Exchange</div>
												<div className='grid-header'>Trading Currency</div>
												<div className='grid-header'>Bloomberg Code</div>
												<div className='grid-header'>ISIN</div>
												<div className='grid-header'>SEDOL</div>
												<div className='grid-header'>WKN</div>
												<div className='grid-header'>Listing Date</div>
											</div>
											{listingCodes.map((o, i) => (
												<div className='grid-row' key={i}>
													<div className='grid-column'>Country</div>
													<div className='grid-value'>{o.country}</div>
													<div className='grid-column'>Exchange</div>
													<div className='grid-value'>{o.exchangeName}</div>
													<div className='grid-column'>Trading Currency</div>
													<div className='grid-value'>{o.Currency}</div>
													<div className='grid-column'>Bloomberg Code</div>
													<div className='grid-value'>{o.bloombergCode}</div>
													<div className='grid-column'>ISIN</div>
													<div className='grid-value'>{o.isin}</div>
													<div className='grid-column'>SEDOL</div>
													<div className='grid-value'>{o.sedol}</div>
													<div className='grid-column'>WKN</div>
													<div className='grid-value'>{o.wkn}</div>
													<div className='grid-column'>Listing Date</div>
													<div className='grid-value'>{o.listingDate}</div>
												</div>
											))}
										</div>

										{documents_link && documents_link.cached_url && (
											<Link className='document-link' to={'/' + documents_link.cached_url}>
												<span>We publish supporting documents to accompany our Etps</span>
												<span style={{ display: 'flex', columnGap: 40, alignItems: 'center' }}>
													View Etp Documents <img style={{ height: 42 }} src={ChevronLarge} alt='forward' />
												</span>
											</Link>
										)}
									</div>
								)}
								{selectedKey === '1' && structure && (
									<div>
										<table>
											<tbody>
												{structure.map((o) => {
													const fieldName = datasourceEntries.find((de) => de.name === o.key)
													return (
														<tr key={o.key}>
															<td style={{ borderBottom: '1px solid #122444', fontWeight: 'bold' }}>{fieldName?.dimensionValue || fieldName?.value || o.key}</td>
															<td style={{ borderBottom: '1px solid #122444' }}>{o.value}</td>
														</tr>
													)
												})}
											</tbody>
										</table>
									</div>
								)}
								{selectedKey === '2' && <div>{document_list && document_list.map((blok) => <StoryblokComponent blok={blok} key={blok._uid} />)}</div>}
								{selectedKey === '3' && keyserviceProvider && (
									<div>
										<table>
											<tbody>
												{keyserviceProvider.map((o) => {
													const fieldName = datasourceEntries.find((de) => de.name === o.key)
													return (
														<tr key={o.key}>
															<td style={{ borderBottom: '1px solid #122444', fontWeight: 'bold' }}>{fieldName?.dimensionValue || fieldName?.value || o.key}</td>
															<td style={{ borderBottom: '1px solid #122444' }}>{o.value}</td>
														</tr>
													)
												})}
											</tbody>
										</table>
									</div>
								)}

								{selectedKey === '4' && legalAndTax && (
									<div>
										<table>
											<tbody>
												{legalAndTax.map((o) => {
													const fieldName = datasourceEntries.find((de) => de.name === o.key)
													return (
														<tr key={o.key}>
															<td style={{ borderBottom: '1px solid #122444', fontWeight: 'bold' }}>{fieldName?.dimensionValue || fieldName?.value || o.key}</td>
															<td style={{ borderBottom: '1px solid #122444' }}>{o.value}</td>
														</tr>
													)
												})}
											</tbody>
										</table>
									</div>
								)}
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}

export default KeyInformation
