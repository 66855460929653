import React, { useState, useRef } from 'react'
import { useD3 } from '../../hooks'
import * as d3 from 'd3'

const height = 500
const width = 500

const margin = { top: 0, right: 0, bottom: 0, left: 0 }
const chartColors = ['#525E75', '#122444', '#FFD964', '#EEAC56', '#9747FF', '#979797', '#0E63DB', '#FF0000', '#7DC7F5', '#D9D9D9']

export const BoxsetRowContentGraphPie = ({ series, updated, columns = [] }) => {
	const [selectedSeries, setSelectedSeries] = useState()

	const graphContainerRef = useRef()
	const ref = useD3(
		(svg) => {
			if (series && svg) {
				const data = series

				const radius = Math.min(width - margin.left - margin.right, height - margin.top - margin.bottom) / 2

				const pie = d3.pie().value((d) => +d.weight)
				const data_ready = pie(data)

				svg.select('.plot-area')
					.attr('transform', 'translate(' + width / 2 + ',' + height / 2 + ')')
					.selectAll('path')
					.data(data_ready)
					.join('path')
					.attr(
						'd',
						d3
							.arc()
							.innerRadius(width / 5) // This is the size of the donut hole
							.outerRadius(radius)
					)
					.attr('stroke', 'white')
					.style('stroke-width', '1px')
					.on('mouseenter', (event, d) => setSelectedSeries(d.index))
					.on('mouseout', (event, d) => setSelectedSeries(undefined))
					.transition()
					.attr('fill', (d) => {
						if (selectedSeries === undefined || d.index === selectedSeries) return chartColors[d.index]
						return '#979797'
					})
					.style('opacity', (d) => {
						if (selectedSeries === undefined || d.index === selectedSeries) return 1
						return 0.1
					})
			}
		},
		[series, selectedSeries]
	)

	return (
		<div className='row'>
			<div className='col-12 col-lg-6'>
				<div ref={graphContainerRef} className='donut-graph-container'>
					<svg ref={ref} className='donut-graph' viewBox={`0 0 ${width} ${height}`} preserveAspectRatio='xMinYMin meet'>
						<g className='plot-area' />
						<g className='segment' />
					</svg>
					<div className='donut-graph-header'>
						{selectedSeries === undefined ? (
							<h5 className='display'>Hover segments for breakdown</h5>
						) : (
							<>
								<span className='title'>{series[selectedSeries].displayName}</span>
								{columns
									.filter((col) => col !== 'displayName')
									.map((col) => (
										<span key={col}>
											{col}: {series[selectedSeries][col]}
										</span>
									))}
							</>
						)}
					</div>
				</div>
			</div>
			<div className='col-12 col-lg-6'>
				<table className='pie-breakdown-table'>
					<thead>
						<tr>
							{columns.map((col) => (
								<th key={col}>
									<h5>{col.split(/(?=[A-Z])/).join(' ')}</h5>
								</th>
							))}
						</tr>
					</thead>
					<tbody>
						{series &&
							series.map((o, i) => (
								<tr onMouseEnter={() => setSelectedSeries(i)} onMouseLeave={() => setSelectedSeries(undefined)} key={o.key + i} className={i === selectedSeries ? 'selected' : ''}>
									{columns.map((col, index) => (
										<td key={col}>
											{index === 0 && (
												<div className='d-flex align-items-center' style={{ columnGap: 15 }}>
													<span className='circle-legend' style={{ backgroundColor: chartColors[i] }}></span>
													<span className='asset'>{o[col]}</span>
												</div>
											)}
											{index !== 0 && <span>{o[col]}</span>}
										</td>
									))}
									{/* 
									<td>
										<div className='d-flex align-items-center' style={{ columnGap: 15 }}>
											<span className='circle-legend' style={{ backgroundColor: chartColors[i] }}></span>
											<span className='asset'>{o.displayName}</span>
										</div>
									</td>
									<td>
										<span className='weight-indicator'>
											<span>{+o.weight}%</span>
											<span className={`indicator-${o.indicator === '-' ? 'negative' : o.indicator === '+' ? 'positive' : 'neutral'}`} />
										</span>
									</td>
									<td className='d-none d-lg-table-cell'>{o.coinEntitlement}</td>
									<td className='d-none d-lg-table-cell'>{o.price}</td> */}
								</tr>
							))}
					</tbody>
				</table>

				{updated ? (
					<h5 className='label' style={{ marginTop: 40 }}>
						Last Updated {updated}
					</h5>
				) : undefined}
			</div>
		</div>
	)
}

export default BoxsetRowContentGraphPie
