import React, { useEffect, useState } from 'react'
import { storyblokEditable } from '@storyblok/react'

export const ETPsTotalAmountInvested = ({ blok }) => {
	const { title } = blok
	const [amount, setAmount] = useState()
    const [suffix, setSuffix] = useState()

	useEffect(() => {
		fetch(`${process.env.REACT_APP_API_URI}/Widgets?ApiKey=${process.env.REACT_APP_API_KEY}&names=ETP_AUM`, {
			method: 'GET',
			headers: {
				'Content-Type': 'application/json',
			},
		}).then((value) => {
			value.json().then((value) => {
				const etpMeta = value.find((v) => v.key === 'ETP_AUM')?.sections.find((v) => v.key === 'ETP_AUM')?.meta

				setAmount(etpMeta?.find((o) => o.key === 'MarketCap')?.value)
                setSuffix(etpMeta?.find((o) => o.key === 'MarketCap')?.suffix)
			})
		})
	}, [])

	return (
		<div {...storyblokEditable(blok)}>
			<div className='investment'>
				<div className='investment-header'>{title}</div>
				<div className='investment-amount'>
					{amount} <span className='investment-amount-currency'>{suffix}</span>
				</div>
			</div>
		</div>
	)
}

export default ETPsTotalAmountInvested
