import React from 'react'

import { storyblokEditable, StoryblokComponent } from '@storyblok/react'

export const Information = ({ blok }) => {
	const { anchor_key, background } = blok

	return (
		<div className={background === 'white' ? 'light' : background} id={anchor_key}>
			<div {...storyblokEditable(blok)} className='information'>
				<div className='container'>
					<div className='row'>
						<div className='col-lg-4 col-md-6 col-12'>
							<h5>{blok.title}</h5>
						</div>
						<div className='col-lg-8 col-md-6 col-12'>
							{blok.paragraph.map((blok) => (
								<StoryblokComponent content={true} blok={blok} key={blok._uid} />
							))}
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}

export default Information
