import React, { useEffect, useMemo, useState } from 'react'
import orderBy from 'lodash.orderby'
import BoxsetRowContentGraphPie from './BoxsetRowContentGraphPie'
import { formatDate } from '../Quote'
import BoxsetRowContentGraphBarLine from './BoxsetRowContentGraphBarLine'
import BoxsetRowContentGraphTimeSeries from './BoxsetRowContentGraphTimeSeries'

export const BoxsetRowContentGraph = ({ blok }) => {
	const { title, description, graph_type, weighted_columns, series: blokSeries, y_axis_title } = blok
	const [series, setSeries] = useState([])
	const [updated, setUpdated] = useState()

	const graphQueries = useMemo(
		() => [
			{
				type: 'weighted',
				query: `${process.env.REACT_APP_API_URI}/Widgets?ApiKey=${process.env.REACT_APP_API_KEY}&names=BOX_GRAPH_WEIGHTED`,
			},
			{
				type: 'bar_line',
				query: `${process.env.REACT_APP_API_URI}/Widgets?ApiKey=${process.env.REACT_APP_API_KEY}&names=BOX_GRAPH_BARLINE`,
			},
			{
				type: 'time_series',
				query: `${process.env.REACT_APP_API_URI}/Widgets?ApiKey=${process.env.REACT_APP_API_KEY}&names=BOX_GRAPH_TIMESERIES`,
			},
		],
		[]
	)

	useEffect(() => {
		const url = graphQueries.find((gq) => gq.type === graph_type).query

		fetch(`${url}&boxseries=${blokSeries.map(o => o.identifier).join(',')}`, {
			method: 'GET',
			headers: {
				'Content-Type': 'application/json',
			},
		}).then((value) => {
			value.json().then((value) => {
				const graphType = graph_type.split('_').join('').toUpperCase()
				const widget = value.find((val) => val.key === `GRAPH_WIDGET_${graphType}`)

				if (graph_type === 'bar_line' || graph_type === 'time_series') {
					const section = widget.sections.find((section) => section.key === `GRAPH_SECTION_${graphType}`)
					const { graph } = section
					const { series } = graph

					setSeries(series)
				} else if (graph_type === 'weighted') {
					const sections = widget.sections

					const data = []

					for (let i = 0; i < sections.length; i++) {
						const element = sections[i]
						const item = { key: element.key }

						for (let c = 0; c < element.meta.length; c++) {
							const metaElement = element.meta[c]
							item[metaElement.key] = metaElement.value
						}

						data.push(item)
					}

					setSeries(orderBy(data, ['weight'], ['desc']))
					setUpdated(formatDate(new Date(widget.updated)))
				}
				// const section = widget.sections.find((section) => section.key === `GRAPH_SECTION_${isin || bloomberg}`)
				// const { graph } = section
				// const { series } = graph

				// setSeries(series)
				// setSelectedSeries([...(blok.series?.map((o) => o.identifier?.toUpperCase()) || ''), isin || bloomberg])
				// const overviewWidget = value.find((v) => v.key === `${isin ? 'ISIN' : 'INDEX'}_KEYSTATS_${isin || bloomberg}`)
				// setOverview(overviewWidget.sections.find((v) => v.key === (isin ? `${isin}_OVERVIEW` : bloomberg))?.meta)
			})
		})
	}, [graph_type, blokSeries, graphQueries])

	return (
		<div>
			<div>
				{title && <h5>{title}</h5>}
				{description && <p>{description}</p>}
			</div>

			{graph_type === 'weighted' && series ? <BoxsetRowContentGraphPie updated={updated} series={series} columns={weighted_columns.split(',')} /> : undefined}
			{graph_type === 'bar_line' && series && blokSeries ? <BoxsetRowContentGraphBarLine series={series} blokSeries={blokSeries} axisLabel={y_axis_title}/> : undefined}
			{graph_type === 'time_series' && series && blokSeries ? <BoxsetRowContentGraphTimeSeries series={series} blokSeries={blokSeries} axisLabel={y_axis_title}/> : undefined}
		</div>
	)
}

export default BoxsetRowContentGraph
