import React from 'react'
import { storyblokEditable } from '@storyblok/react'

export const DailyHedgingPosition = ({ blok }) => {
	const { anchor_key, background = 'gray', title, header, link, link_text, image_link, description } = blok

	return (
		<div className={background.toLowerCase()} id={anchor_key}>
			<div id='daily_hedging_position' {...storyblokEditable(blok)} className='widget'>
				<div className='container'>
					<div className='row'>
						<div className='col-lg-4 col-sm-12'>
							<h5>{title}</h5>
						</div>
						<div className='col-lg-8 col-sm-12'>
                            <div className="paragraph" style={{paddingRight: 0}}>
                                <h3 className="content">
                                    {header}
                                </h3>

                                <p style={{margin: 0}}>{description}</p>

                                <a href={link.cached_url} className="action-link">{link_text} <img src="/images/ChevronRightDark.svg" alt="chevron" /></a>

                                <div className="divider"></div>

                                {image_link.map(({ _uid, image, link }) => {
                                    return <a href={link.url} key={_uid} target="_blank" rel="noreferrer"><img   src={image.filename} alt={title} /></a>
                                })}
                            </div>

                        </div>
					</div>
				</div>
			</div>
		</div>
	)
}

export default DailyHedgingPosition
