import React, { useMemo, useRef, useState } from 'react'
import { Link } from 'react-router-dom'
import Highlighter from 'react-highlight-words'
import { useGlobalState } from '../../store'
import { TeachingBubble, DirectionalHint } from '@fluentui/react'
import { extractRichText } from '../RichText'

const HighlightTag = ({ children, renderTooltip }) => {
	const [visible, setVisible] = useState(false)
	const tipItem = useRef()
    
	const { lookupTerms, knowledgeBytes } = useGlobalState()

    const knowledgeByte = useMemo(() => {
        if (!renderTooltip) return undefined
        if (knowledgeBytes.length === 0) return undefined

        const lookupTerm = lookupTerms.find(lt => lt.value === children.toLowerCase())

        if (!lookupTerm) return undefined
        return knowledgeBytes.find(o => o.key === lookupTerm.key)
    }, [renderTooltip, knowledgeBytes, lookupTerms, children])

	return (
		<>
			<span
				className={`text-bold ${renderTooltip ? 'tooltip-bubble': ''}`}
				ref={tipItem}
				onClick={(ev) => {
					setVisible(true)
					ev.preventDefault()
				}}
			>
				{children}
			</span>

			{visible && renderTooltip && (
				<TeachingBubble
					target={tipItem}
					calloutProps={{
                        isBeakVisible: false,
						directionalHint: DirectionalHint.topLeftEdge,
						// style: {
						// 	backgroundColor: Colors.Primary,
						// },
                        className: 'knowledge-card-bubble',
						styles: {
							beak: { backgroundColor: 'transparent' },
                            calloutMain: {
                                background: 'white',
                                color: '#122444',

                            }
						},
					}}
					onDismiss={() => {
						setVisible(false)
					}}
				>
					<h5>{knowledgeByte?.title}</h5>
                    <p style={{margin:0}}>{knowledgeByte?.description || extractRichText({ blok: knowledgeByte?.body })}</p>
                    <Link to={'/' + knowledgeByte?.full_slug} className='knowledge-card-link'>View Card</Link>
				</TeachingBubble>
			)}
		</>
	)
}

export const GlossaryHighlighter = (props) => {
	const { glossaryTerms, textToHighlight, renderTooltip } = props
	// console.log('glossaryTerms', glossaryTerms)
	const highlightComp = useMemo(() => <Highlighter searchWords={glossaryTerms} autoEscape={true} textToHighlight={textToHighlight} highlightTag={({ children }) => <HighlightTag renderTooltip={renderTooltip} >{children}</HighlightTag>} />, [renderTooltip, glossaryTerms, textToHighlight])

	return highlightComp
}
